import React from 'react';
import styles from './styles';
import { Tooltip, IconButton } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { fTimeDate } from '@/actions/Helper';
import { adminUpdateUserApp, deleteAlert, SaveData } from '@/actions';
import {
  DeleteRounded,
  CreateRounded,
  NotificationImportant,
  Notifications,
  NotificationsActive,
  NotificationsTwoTone,
} from '@material-ui/icons';
import { BaseIcon } from '@/Utils';
import { MemberIcons as memberIcons, Icons } from '@/assets';
import clsx from 'clsx';
import AddAlert from './AlertAdd';
import { useHistory } from 'react-router-dom';

export default ({
  DATA = [],
  getAlerts,
  notify,
  terminalsList,
  sslsList,
  portsList,
}) => {
  const cls = styles();
  const [addPopUp, setAddPopUp] = React.useState({
    DATA: null,
    loading: true,
    popUp: false,
  });
  const history = useHistory();

  const handleDeleteItem = (i) => {
    deleteAlert(i)
      .then((res) => {
        getAlerts();
        notify(res.message, 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleUpdateUserApp = (alert) => {
    SaveData({ alertItem: alert });
    history.push('/logs/alerts/add/');
  };

  const renderTableActions = (alert) => {
    const alertId = alert.id;
    let actionItems = [
      // {
      //   icon: <CreateRounded className={cls.editIcon} />,
      //   tooltip: 'Edit',
      //   onClick: () => {
      //     history.push(`/logs/alerts/add/:${alert}`);
      //   },
      // },
      {
        icon: <DeleteRounded className={cls.delIcon} />,
        tooltip: 'Delete',
        onClick: () => {
          handleDeleteItem(alertId);
          // SaveData({ deleteMemberPopup: member.id });
        },
      },
    ];
    return (
      <div className={cls.tableActionsContainer}>
        {actionItems.map((item, index) => (
          <Tooltip arrow title={item.tooltip} placement="top">
            <IconButton onClick={item.onClick}>{item.icon}</IconButton>
          </Tooltip>
        ))}
      </div>
    );
  };

  return (
    <>
      {addPopUp.popUp ? (
        <AddAlert
          terminalsList={terminalsList}
          sslsList={sslsList}
          portsList={portsList}
        />
      ) : (
        ''
      )}
      <div className={cls.content}>
        <div className={cls.tableContainer}>
          <table className={cls.list}>
            <thead className={cls.thead}>
              <th style={{ flex: 1 }}>Name</th>
              <th style={{ flex: 1 }}>Data</th>
              <th style={{ flex: 1 }}>Rule</th>
              <th style={{ flex: 1 }}>Action</th>
              <th style={{ flex: 1 }}>Recipient</th>
              <th style={{ flex: 1 }}>Period</th>
              <th style={{ flex: 1 }}>Status</th>
              <th style={{ flex: 1 }}>Created Date</th>
              <th style={{ flex: 1 }}>Actions</th>
            </thead>
            <tbody className={cls.tbody}>
              <tr
                onClick={() => {
                  // setAddPopUp((prev) => {
                  //   return {
                  //     ...prev,
                  //     popUp: true,
                  //     DATA: 'shift_message',
                  //   };
                  // });
                  history.push('/logs/alerts/add');
                }}
                className={cls.tableRow}>
                <td className={cls.addAvatar}>
                  <NotificationsTwoTone
                    src={memberIcons.addMember}
                    className={clsx(cls.addNotifIcon)}
                  />
                </td>
                <span
                  onClick={() => {
                    // SaveData({ addMemberPopup: true });
                  }}
                  className={cls.addMemberText}>
                  Add Alert
                </span>
              </tr>

              {DATA.map((i, idx) => {
                return (
                  <tr className={cls.tableRow} key={idx}>
                    <td style={{ flex: 1 }}>{i.name}</td>
                    <td style={{ flex: 1 }}>{i.data_source}</td>
                    <td style={{ flex: 1 }}>{i.rule}</td>
                    <td style={{ flex: 1 }}>{i.action}</td>
                    <td style={{ flex: 1 }}>{i.action_recipient}</td>
                    <td style={{ flex: 1 }}>{i.alert_period}</td>
                    <td style={{ flex: 1 }}>{i.status}</td>
                    <td style={{ flex: 1 }}>{i.created_at}</td>
                    <td style={{ flex: 1 }}>
                      <div className={cls.tableActionsContainer}>
                        <Tooltip arrow title="Edit" placement="top">
                          <IconButton onClick={() => handleUpdateUserApp(i)}>
                            <CreateRounded className={cls.editIcon} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {renderTableActions(i)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
