import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import closeIcon from '../../assets/img/closeIcon.svg';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { useOutsideAlerter } from '@/hocs';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import { TextareaAutosize } from '@mui/material';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import DateInput from '@/components/DateInput';
import {
  addAlert,
  getAlertsFormData,
  getSslList,
  getTerminalsList,
} from '@/actions';
import { useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';

export default ({ portsList, terminalsList }) => {
  const cls = styles();
  const expDateRef = React.useRef(null);
  const history = useHistory();

  const setForm = (it, val) => setFormData({ ...form, [it]: val });
  const notify = useNotify();
  const selectOp = useSelector((state) => state.reducer);
  const [alertsFormData, setAlertsFormData] = useState([]);

  const alertItemState = useSelector((state) => state.reducer?.alertItem);
  console.log('alertItemState', alertItemState);

  useEffect(() => {
    if (alertItemState) {
      setFormData({
        name: alertItemState?.name,
        data: { label: alertItemState?.data_source },
        port: { label: alertItemState?.port },
        mode: '',
        ssl: '',
        terminal: '',
        action: '',
        period: { label: alertItemState?.alert_period },
        role: '',
        interval: '',
        actionEmail: '',
        expireDate: new Date(),
        status: '',
      });
    }
  }, [alertItemState]);

  const getAlertsForm = () => {
    getAlertsFormData()
      .then((res) => {
        console.log('res', res);

        setAlertsFormData(transformData(res));
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    getAlertsForm();
  }, []);

  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toLowerCase() : match.toUpperCase()
      )
      .replace(/\s+/g, '');
  };

  const transformData = (apiRes) => {
    if (!apiRes || typeof apiRes !== 'object') {
      throw new Error('Invalid API response.');
    }

    // Transforming 'data_sources'
    const dataSources = apiRes.data_sources
      ? Object.entries(apiRes.data_sources).map(([key, id]) => ({
          label: key,
          value: toCamelCase(key),
          id: id,
        }))
      : [];

    // Transforming 'shipping_lines'
    const shippingLines = Array.isArray(apiRes.shipping_lines)
      ? apiRes.shipping_lines.map(({ id, value, label }) => ({
          id,
          value,
          label,
        }))
      : [];

    // Transforming 'terminals'
    const terminals = Array.isArray(apiRes.terminals)
      ? apiRes.terminals.map(({ id, value, label }) => ({
          id,
          value,
          label,
        }))
      : [];

    // Transforming 'rules'
    const rules = apiRes.rules
      ? Object.entries(apiRes.rules).map(([key, id]) => ({
          label: key,
          value: toCamelCase(key),
          id: id,
        }))
      : [];

    // Transforming 'rule_custom_fields'
    const ruleCustomFields = apiRes.rule_custom_fields
      ? Object.entries(apiRes.rule_custom_fields).reduce(
          (acc, [key, fields]) => {
            acc[key] = fields
              ? Object.entries(fields).map(([fieldKey, id]) => ({
                  label: fieldKey,
                  value: toCamelCase(fieldKey),
                  id: id,
                }))
              : [];
            return acc;
          },
          {}
        )
      : {};

    // Transforming 'actions'
    const actions = apiRes.actions
      ? Object.entries(apiRes.actions).map(([key, id]) => ({
          label: key,
          value: toCamelCase(key),
          id: id,
        }))
      : [];
    // Transforming 'ports'
    const ports = apiRes.ports
      ? Object.entries(apiRes.ports).map(([key, id]) => ({
          label: key,
          value: toCamelCase(key),
          id: id,
        }))
      : [];

    // Transforming 'periods'
    const periods = apiRes.periods
      ? Object.entries(apiRes.periods).map(([key, id]) => ({
          label: key,
          value: toCamelCase(key),
          id: id,
        }))
      : [];

    // Transforming 'statuses'
    const statuses = apiRes.statuses
      ? Object.entries(apiRes.statuses).map(([key, id]) => ({
          label: key,
          value: toCamelCase(key),
          id: id,
        }))
      : [];

    // Returning transformed data
    return {
      dataSources,
      shippingLines,
      terminals,
      rules,
      ruleCustomFields,
      actions,
      periods,
      statuses,
      ports,
    };
  };

  useEffect(() => {
    if (terminalsList?.length > 0) {
      getAlertsForm();
    }
  }, [terminalsList]); // Dependencies array with portsList

  const modeList = [
    {
      label: 'Terminal',
      value: 'terminal',
    },
    {
      label: 'Shipping Line',
      value: 'shippingLine',
    },
  ];
  const [form, setFormData] = React.useState({
    name: '',
    data: '',
    port: '',
    mode: '',
    ssl: '',
    terminal: '',
    action: '',
    period: '',
    role: '',
    interval: '',
    actionEmail: '',
    expireDate: new Date(),
    status: '',
  });
  const [formSend, setFormSendData] = React.useState({
    name: form.name,
    data_source: form.data.id,
    port: form.port.id,
    terminal_id: form.terminal.id,
    shipping_line_id: form.ssl.id,
    rule: form.rule?.id,
    rule_specific_time: form.interval,
    action: form.action.id,
    action_email: form.actionEmail,
    alert_period: form.period.id,
    alert_period_expiration_date: form.expireDate,
    status: 1,
  });
  useEffect(() => {
    setFormSendData({
      name: form.name,
      data_source: form.data.id,
      port: form.port.id,
      terminal_id: form.terminal.id,
      shipping_line_id: form.ssl.id,
      rule: form.rule?.id,
      rule_specific_time: form.interval,
      action: form.action.id,
      action_email: form.actionEmail,
      alert_period: form.period.id,
      alert_period_expiration_date: form.expireDate,
      status: 1,
    });
  }, [form]);

  const handleAddAlert = (form) => {
    if (form.name === '') {
      notify('Please Fill all fields!', 'warning');
    }
    console.log('formApi', form);

    addAlert(form)
      .then((res) => {
        console.log('ApiAddRess', res);
        history.push('/logs/alerts');

        notify('Successfully Uploaded!', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  return (
    <div className={cls.content}>
      <div className={cls.container}>
        <div className={cls.title}>Add New Alert</div>
        <div className={cls.pageContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={`${cls.input} ${cls.containerInput}`}>
                <span className={cls.containerBadge}>{`Alert Name`}</span>
                <TextField
                  type="text"
                  placeholder="Alert Name"
                  className={cls.input}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ min: 1 }}
                  value={form.name}
                  onChange={(item) => setForm('name', item.target.value)}
                />
              </div>
            </Grid>
          </Grid>
          <h2 className={cls.sectionTitle}>1. Data</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={`${cls.input} ${cls.containerInput}`}>
                <span className={cls.containerBadge}>{`Data `}</span>
                <Select
                  placeholder="Data"
                  className={cls.select}
                  value={form.data}
                  onChange={(item) => {
                    setForm('data', item);
                    // setForm('mode', '');
                  }}
                  options={alertsFormData.dataSources}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={`${cls.input} ${cls.containerInput}`}>
                <span className={cls.containerBadge}>{`Ports `}</span>
                <Select
                  placeholder="Ports"
                  className={cls.select}
                  value={form.port}
                  onChange={(item) => setForm('port', item)}
                  options={alertsFormData.ports}
                />
              </div>
            </Grid>
            {form.data.value === 'gateSchedule' ||
            form.data.value === 'emptyReceviables' ? (
              <Grid item xs={12} sm={12} md={12}>
                <div className={`${cls.input} ${cls.containerInput}`}>
                  <span className={cls.containerBadge}>{`Terminal `}</span>
                  <Select
                    placeholder="Terminal"
                    className={cls.select}
                    value={form.terminal}
                    onChange={(item) => setForm('terminal', item)}
                    options={alertsFormData.terminals}
                  />
                </div>
              </Grid>
            ) : form.data.value === 'emptyTracking' ||
              form.data.value === 'exportTraking' ? (
              <Grid item xs={12} sm={12} md={12}>
                <div className={`${cls.input} ${cls.containerInput}`}>
                  <span className={cls.containerBadge}>{`Shipping Line `}</span>
                  <Select
                    placeholder="Shipping Line"
                    className={cls.select}
                    value={form.ssl}
                    onChange={(item) => setForm('ssl', item)}
                    options={alertsFormData.shippingLines}
                  />
                </div>
              </Grid>
            ) : form.data.value === 'vesselSchedule' ? (
              <Grid item xs={12} sm={12} md={12}>
                <div className={`${cls.input} ${cls.containerInput}`}>
                  <span className={cls.containerBadge}>{`Mode `}</span>
                  <Select
                    placeholder="Mode"
                    className={cls.select}
                    value={form.mode}
                    onChange={(item) => setForm('mode', item)}
                    options={modeList}
                  />
                </div>
              </Grid>
            ) : (
              ''
            )}
            {form.mode.value === 'terminal' &&
            form.data.value === 'vesselSchedule' ? (
              <Grid item xs={12} sm={12} md={12}>
                <div className={`${cls.input} ${cls.containerInput}`}>
                  <span className={cls.containerBadge}>{`Terminal `}</span>
                  <Select
                    placeholder="Terminal"
                    className={cls.select}
                    value={form.terminal}
                    onChange={(item) => setForm('terminal', item)}
                    options={alertsFormData.terminals}
                  />
                </div>
              </Grid>
            ) : null}
            {form.mode.value === 'shippingLine' &&
            form.data.value === 'vesselSchedule' ? (
              <Grid item xs={12} sm={12} md={12}>
                <div className={`${cls.input} ${cls.containerInput}`}>
                  <span className={cls.containerBadge}>{`Shipping Line `}</span>
                  <Select
                    placeholder="Shipping Line"
                    className={cls.select}
                    value={form.ssl}
                    onChange={(item) => setForm('ssl', item)}
                    options={alertsFormData.shippingLines}
                  />
                </div>
              </Grid>
            ) : null}
          </Grid>
          <h2 className={cls.sectionTitle}>2. Rules</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={`${cls.input} ${cls.containerInput}`}>
                <span className={cls.containerBadge}>{`Select `}</span>
                <Select
                  placeholder="Select"
                  className={cls.select}
                  value={form.rule}
                  onChange={(item) => setForm('rule', item)}
                  options={alertsFormData.rules}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={`${cls.input} ${cls.containerInput}`}>
                <span
                  className={
                    cls.containerBadge
                  }>{`Time To Interval (min)`}</span>
                <TextField
                  type="number"
                  placeholder="Interval"
                  className={cls.input}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ min: 1 }}
                  value={form.interval}
                  onChange={(item) => setForm('interval', item.target.value)}
                />
              </div>
            </Grid>
          </Grid>
          <h2 className={cls.sectionTitle}>3. Actions</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={`${cls.input} ${cls.containerInput}`}>
                <span className={cls.containerBadge}>{`Action `}</span>
                <Select
                  placeholder="Action"
                  className={cls.select}
                  value={form.action}
                  onChange={(item) => setForm('action', item)}
                  options={alertsFormData.actions}
                />
              </div>
            </Grid>
            {form.action.value === 'sendEmail' ? (
              <Grid item xs={12} sm={12} md={12}>
                <div className={`${cls.input} ${cls.containerInput}`}>
                  <span className={cls.containerBadge}>{`Email`}</span>
                  <TextField
                    type="email"
                    placeholder="Email"
                    className={cls.input}
                    InputProps={{ disableUnderline: true }}
                    value={form.actionEmail}
                    onChange={(item) =>
                      setForm('actionEmail', item.target.value)
                    }
                  />
                </div>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <h2 className={cls.sectionTitle}>4. Alert Period</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={`${cls.input} ${cls.containerInput}`}>
                <span className={cls.containerBadge}>{`Period `}</span>
                <Select
                  placeholder="Period"
                  className={cls.select}
                  value={form.period}
                  onChange={(item) => setForm('period', item)}
                  options={alertsFormData.periods}
                />
              </div>
            </Grid>
            {form.period.value === 'expiresOnSpecificDate' ? (
              <Grid item xs={12} sm={12} md={12}>
                <div className={`${cls.input} ${cls.containerInput}`}>
                  <span className={cls.containerBadge}>{`Exp. Date`}</span>
                  <DateInput
                    ref={expDateRef}
                    placeholder="Exp. Date"
                    className={clsx(cls.date)}
                    format="MM/dd/yyyy"
                    value={form.expireDate}
                    onChange={(item) => {
                      setForm('expireDate', item);
                    }}
                  />
                </div>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </div>

        <span className={cls.msgPopupBtns}>
          <Button
            className={clsx(cls.saveBtn)}
            onClick={(e) => {
              // Close();
              history.push('/logs/alerts');
            }}>
            Cancel
          </Button>
          <Button
            className={clsx(cls.saveBtn)}
            onClick={(e) => {
              handleAddAlert(formSend);
            }}>
            Save
          </Button>
        </span>
      </div>
    </div>
  );
};
