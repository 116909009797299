import React, { forwardRef, useCallback } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { MenuItem, ListItemIcon, Tooltip } from '@material-ui/core';
import styles from './styles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const NavLinkRef = forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

export default forwardRef(({ leftIcon, onClick, sidebarIsOpen }, ref) => {
  const cls = styles();
  const handleMenuTap = useCallback((e) => onClick && onClick(e), [onClick]);
  const user = useSelector((state) => state.reducer.user);

  const { pathname } = useLocation();

  const renderMenuItem = () => (
    <MenuItem
      className={clsx(sidebarIsOpen ? cls.open : cls.close)}
      activeClassName={cls.active}
      component={NavLinkRef}
      ref={ref}
      to="/logs"
      onClick={handleMenuTap}>
      <ListItemIcon className={cls.listItemIcon}>{leftIcon}</ListItemIcon>
      <span className="DashboardItemText">Alerts</span>

      {user.id && user.id === 2 && (
        <div className="RequestResource" style={{ width: '100%' }}>
          <div className={cls.child}>
            <MenuItem
              className={cls.item}
              activeClassName={cls.itemActive}
              component={NavLinkRef}
              ref={ref}
              to={pathname === '/logs' ? '/logs' : '/logs/alerts'}
              onClick={handleMenuTap}>
              Alerts
            </MenuItem>
            <MenuItem
              className={cls.item}
              activeClassName={cls.itemActive}
              component={NavLinkRef}
              ref={ref}
              to="/logs/logs"
              onClick={handleMenuTap}>
              Logs
            </MenuItem>
          </div>
        </div>
      )}
    </MenuItem>
  );

  if (sidebarIsOpen) return renderMenuItem();

  return (
    <Tooltip arrow title="Ai" placement="right">
      {renderMenuItem()}
    </Tooltip>
  );
});
