import React, { useEffect, useState } from 'react';
import styles from './styles';
import stylesIN from './StreetTurnPreferencesStyles';
// import { Grid, TextField, Button } from '@material-ui/core';
import { Tooltip, Checkbox, Grid, TextField, Button } from '@material-ui/core';

import { Loading, useNotify } from 'react-admin';
import { getTableList, setUserSettings } from '@/actions';
import { Icons } from '@/assets';
import { ReactSVG } from 'react-svg';
export default ({ terminalsListRes }) => {
  const [loading, setLoading] = React.useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    dontShareEmptiesOnOwnChassis: false,
    dontShareEmptiesOnOwnChassisAtCustomerSites: false,
    dontShareEmptiesOfferedForOwnExports: false,
    dontShareEmptiesWithAppointments: false,
    dontShareEmptiesBeforeLFD: false,
    dontShareEmptiesBeforeLFDDays: '',
    lookForEmptyContainersBeforeShipmentDelivery: false,
    lookForEmptyContainersBeforeShipmentDeliveryDays: '',
    matchExportShipmentsAutomatically: false,
    flipServiceAvailability: false,
    interestedInRentingChassis: false,
    interestedInRentingChassisDailyRate: '',
    savingLmcFee: '',
    savingContainerMovingRate: '',
    savingPrePullRate: '',
    savingChassisFee: '',
    savingChassisSplitFee: '',
    savingStorageFee: '',
    savingFuelSurgeCharge: '',
    savingCongestionFee: '',
    savingDetentionFee: '',
    savingScaleFee: '',
    savingFlatFee: '',
  });

  const cls = styles();
  const clsIn = stylesIN();
  const notify = useNotify();

  const getStreetTurnPreferencesList = () => {
    setLoading(true);

    getTableList('streetTurnPreferencesData')
      .then((res) => {
        if (res.data?.settings?.data === null) {
          setFormData(formData);
        }
        setFormData(res.data?.settings?.data);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getStreetTurnPreferencesList();
  }, []);

  const handleCheckboxChange = (name) => (event) => {
    const { checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const handleInputChange = (name) => (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const req = {
      key: 'streetTurnPreferencesData',
      data: formData,
    };
    setLoading(true);

    setUserSettings(req)
      .then((res) => {
        // setFormData(res.data?.settings?.data);
        setLoading(false);

        // window.location.reload();
      })
      .catch((err) => {
        console.log('posterr', err);
        notify(err, 'warning');
      });
  };

  const findNameById = (relationId) => {
    const item = terminalsListRes.find(
      (listItem) => listItem.id === parseInt(relationId, 10)
    );
    return item ? item.name : 'Unknown';
  };

  return loading ? (
    <Loading />
  ) : (
    <div className={cls.referContainer}>
      <div className={clsIn.container}>
        <div className={cls.title}>Street Turn Preferences</div>
        <section className={clsIn.section}>
          <h2 className={clsIn.sectionTitle}>Street Turn Empty Availability</h2>

          <div className={clsIn.inputGroup}>
            <Checkbox
              checked={formData?.dontShareEmptiesOnOwnChassis}
              onChange={handleCheckboxChange('dontShareEmptiesOnOwnChassis')}
              className={clsIn.checkbox}
            />
            <label className={clsIn.label}>
              Don't Share Empty Containers on Own Chassis
              <Tooltip
                arrow
                title="Offer containers mounted on your own chassis for street turns."
                placement="top">
                <ReactSVG src={Icons.infoIcon} className={cls.intoInputIcons} />
              </Tooltip>
            </label>
          </div>

          <div className={clsIn.inputGroup}>
            <Checkbox
              checked={formData?.dontShareEmptiesOnOwnChassisAtCustomerSites}
              onChange={handleCheckboxChange(
                'dontShareEmptiesOnOwnChassisAtCustomerSites'
              )}
              className={clsIn.checkbox}
            />
            <label className={clsIn.label}>
              Don't Share Empty Containers on Own Chassis at Customer Sites
              <Tooltip
                arrow
                title="Make containers on your own chassis available for street turns at customer locations."
                placement="top">
                <ReactSVG src={Icons.infoIcon} className={cls.intoInputIcons} />
              </Tooltip>
            </label>
          </div>

          <div className={clsIn.inputGroup}>
            <Checkbox
              checked={formData?.dontShareEmptiesOfferedForOwnExports}
              onChange={handleCheckboxChange(
                'dontShareEmptiesOfferedForOwnExports'
              )}
              className={clsIn.checkbox}
            />
            <label className={clsIn.label}>
              Don't Offer Empty Containers Only If Not Used for Own Exports
              <Tooltip
                arrow
                title="If an empty container is needed for your own export shipment, do not offer it. Otherwise, make it available for street turns."
                placement="top">
                <ReactSVG src={Icons.infoIcon} className={cls.intoInputIcons} />
              </Tooltip>
            </label>
          </div>

          <div className={clsIn.inputGroup}>
            <Checkbox
              checked={formData?.dontShareEmptiesWithAppointments}
              onChange={handleCheckboxChange(
                'dontShareEmptiesWithAppointments'
              )}
              className={clsIn.checkbox}
            />
            <label className={clsIn.label}>
              Don't Offer Empties for Street Turns with Appointments
              <Tooltip
                arrow
                title="Make empty containers available for street turns, even if they already have appointments."
                placement="top">
                <ReactSVG src={Icons.infoIcon} className={cls.intoInputIcons} />
              </Tooltip>
            </label>
          </div>

          <div className={clsIn.inputGroup}>
            <Checkbox
              checked={formData?.dontShareEmptiesBeforeLFD}
              onChange={handleCheckboxChange('dontShareEmptiesBeforeLFD')}
              className={clsIn.checkbox}
            />
            <label className={clsIn.labelWithInput}>
              Don't Share Empty Containers for Street Turns
              <TextField
                type="number"
                className={cls.textInputInline}
                placeholder=""
                value={formData?.dontShareEmptiesBeforeLFDDays}
                onChange={handleInputChange('dontShareEmptiesBeforeLFDDays')}
                InputProps={{ disableUnderline: true }}
                inputProps={{ min: 1 }}
              />
              <span>Days before the Last Free Day (LFD)</span>
            </label>
          </div>
        </section>

        {/* Street Turn Export Availability */}
        <section className={clsIn.section}>
          <h2 className={clsIn.sectionTitle}>
            Street Turn Export Availability
          </h2>

          <div className={clsIn.inputGroup}>
            <Checkbox
              checked={formData?.lookForEmptyContainersBeforeShipmentDelivery}
              onChange={handleCheckboxChange(
                'lookForEmptyContainersBeforeShipmentDelivery'
              )}
              className={clsIn.checkbox}
            />
            <label className={clsIn.labelWithInput}>
              Look for Empty Containers for Export Shipments
              <TextField
                type="number"
                className={cls.textInputInline}
                placeholder=""
                value={
                  formData?.lookForEmptyContainersBeforeShipmentDeliveryDays
                }
                onChange={handleInputChange(
                  'lookForEmptyContainersBeforeShipmentDeliveryDays'
                )}
                InputProps={{ disableUnderline: true }}
                inputProps={{ min: 1 }}
              />
              <span>Days before the Delivery Date</span>
            </label>
          </div>

          <div className={clsIn.inputGroup}>
            <Checkbox
              checked={formData?.matchExportShipmentsAutomatically}
              onChange={handleCheckboxChange(
                'matchExportShipmentsAutomatically'
              )}
              className={clsIn.checkbox}
            />
            <label className={clsIn.label}>
              Match Export Shipments Automatically
              <Tooltip
                arrow
                title="Automatically look for empty containers for export shipments when none are available within your company."
                placement="top">
                <ReactSVG src={Icons.infoIcon} className={cls.intoInputIcons} />
              </Tooltip>
            </label>
          </div>
        </section>

        {/* Chassis & Flip Service Availability */}
        <section className={clsIn.section}>
          <h2 className={clsIn.sectionTitle}>
            Chassis & Flip Service Availability
          </h2>

          <div className={clsIn.inputGroup}>
            <Checkbox
              checked={formData?.flipServiceAvailability}
              onChange={handleCheckboxChange('flipServiceAvailability')}
              className={clsIn.checkbox}
            />
            <label className={clsIn.label}>
              Flip Service Availability{' '}
              <Tooltip
                arrow
                title="Offer flip services to transfer containers between chassis for street turn operations."
                placement="top">
                <ReactSVG src={Icons.infoIcon} className={cls.intoInputIcons} />
              </Tooltip>
            </label>
          </div>

          <div className={clsIn.inputGroup}>
            <Checkbox
              checked={formData?.interestedInRentingChassis}
              onChange={handleCheckboxChange('interestedInRentingChassis')}
              className={clsIn.checkbox}
            />
            <label className={clsIn.label}>
              Interested in Renting Out Own Chassis
            </label>
          </div>

          <div className={clsIn.inputGroup}>
            <label className={clsIn.labelinterestedInRentingChassisDailyRate}>
              Daily Rate:
              <div className={`${cls.input} ${cls.containerInput}`}>
                <span className={cls.containerBadge}>{`$/day`}</span>
                <TextField
                  type="number"
                  className={cls.textInput}
                  placeholder=""
                  value={formData?.interestedInRentingChassisDailyRate}
                  onChange={handleInputChange(
                    'interestedInRentingChassisDailyRate'
                  )}
                  InputProps={{ disableUnderline: true }}
                />
              </div>
            </label>
          </div>
        </section>

        {/* Estimated Saving */}
        <section className={clsIn.section}>
          <h2 className={clsIn.sectionTitle}>Estimated Saving</h2>

          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>
              LMC Fee for Returning Empty from Yard to Terminal
            </label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingLmcFee}
                onChange={handleInputChange('savingLmcFee')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>

          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Container Moving Rate</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$/Mile`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingContainerMovingRate}
                onChange={handleInputChange('savingContainerMovingRate')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>

          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Prepull Rate</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingPrePullRate}
                onChange={handleInputChange('savingPrePullRate')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>

          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Chassis Fee</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$/day`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingChassisFee}
                onChange={handleInputChange('savingChassisFee')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>

          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Chassis Split Fee</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingChassisSplitFee}
                onChange={handleInputChange('savingChassisSplitFee')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>
          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Storage Fee</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingStorageFee}
                onChange={handleInputChange('savingStorageFee')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>
          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Fuel Surge Charge</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingFuelSurgeCharge}
                onChange={handleInputChange('savingFuelSurgeCharge')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>
          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Congestion Fee</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingCongestionFee}
                onChange={handleInputChange('savingCongestionFee')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>
          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Detention Fee</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingDetentionFee}
                onChange={handleInputChange('savingDetentionFee')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>
          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Scale Fee</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingScaleFee}
                onChange={handleInputChange('savingScaleFee')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>
          <div className={clsIn.inputGroupWithLine}>
            <label className={clsIn.label}>Flat Fee</label>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`$`}</span>
              <TextField
                type="number"
                className={cls.textInput}
                placeholder=""
                value={formData?.savingFlatFee}
                onChange={handleInputChange('savingFlatFee')}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>
        </section>
      </div>

      <Grid className={cls.terminalcontent}>
        <Grid className={cls.pageContent} item xs={12} md={12}>
          <div>
            <div className={cls.terminalCInputContainer}>
              <span />
              <Button
                onClick={() => handleSubmit()}
                className={cls.saveTerminalCredBtn}>
                Save
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
