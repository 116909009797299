// in src/Menu.js
import React from 'react';
import { BaseIcon } from '@/Utils';
import { ChatBarIcons, SidebarIcons as Icons } from '@/assets';
import MenuItemLink from './MenuItemLink';
import RequestMenu from './RequestMenu';
import OrderMenu from './OrderMenu';
import styles from './styles';
import { usePermissions } from 'react-admin';
import ContentMenu from './ContentMenu';
import { SaveData } from '@/actions';
import IntegrationMenu from './IntegrationMenu';
import MembersMenu from './MembersMenu';
import FeaturesMenu from './FeaturesMenu';
import InventoryMenu from './InventoryMenu';
import SearchMenu from './SearchMenu';
import ExportMenu from './ExportMenu';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TerminalMenu from './TerminalMenu';
import AiMenu from './AiMenu';
import ScheduleAdmin from './ScheduleAdmin';
import InHouseMenu from './InHouseMenu';
import LogsMenu from './LogsMenu';

export default ({ onMenuClick }) => {
  const cls = styles();

  const { pathname } = useLocation();

  // const AINotif = useSelector(
  //   (state) => state.reducer.notif['whatsapp-messages'] || null
  // );
  const enable_schedule = useSelector(
    (state) => state.reducer.user?.metas?.enable_schedules?.value
  );
  const street_turn = useSelector(
    (state) => state.reducer.user?.metas?.enable_street_turn?.value
  );
  const mptyTrackingEnable = enable_schedule;
  const enable_street_turn = street_turn;

  const MenuItem = ({ to, title, icon, onClick, hasBadge = false }) => (
    <div style={{ position: 'relative' }}>
      <MenuItemLink
        key={to}
        to={`/${to}`}
        primaryText={title}
        leftIcon={<BaseIcon src={icon} className={cls.icon} />}
        onClick={onClick}
        sidebarIsOpen
        hasBadge={hasBadge}
      />
      {hasBadge && (
        <div
          style={{
            width: '15px',
            height: '15px',
            background: 'red',
            borderRadius: '50%',
            position: 'absolute',
            right: 33,
            top: 3,
          }}
        />
      )}
    </div>
  );
  const { permissions } = usePermissions();

  const closeMenu = () => {
    SaveData({ openUserMenu: false });
  };

  return (
    <div>
      {permissions === 'admin' && [
        <MembersMenu
          leftIcon={<BaseIcon src={Icons.Members} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <ContentMenu
          leftIcon={<BaseIcon src={Icons.Content} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <ExportMenu
          leftIcon={<BaseIcon src={Icons.Content} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <ScheduleAdmin
          leftIcon={<BaseIcon src={Icons.Ports} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <MenuItem
          onClick={() => closeMenu()}
          to="chat"
          title="Chat"
          icon={ChatBarIcons.chat}
        />,
        <MenuItem
          onClick={() => closeMenu()}
          to="finance"
          title="Finance"
          icon={Icons.Finance}
        />,
        <IntegrationMenu
          leftIcon={<BaseIcon src={Icons.Integration} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <FeaturesMenu
          leftIcon={<BaseIcon src={Icons.Update} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <AiMenu
          leftIcon={<BaseIcon src={ChatBarIcons.AI} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <LogsMenu
          leftIcon={<BaseIcon src={Icons.alertadd} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,

        // <MenuItem
        //   onClick={() => closeMenu()}
        //   to="AI"
        //   title="AI"
        //   icon={ChatBarIcons.AI}
        //   // hasBadge={AINotif && !pathname.includes('AI') ? true : false}
        // />,
        // <MenuItem
        //   onClick={() => closeMenu()}
        //   to="logs"
        //   title="Logs"
        //   icon={Icons.Logs}
        // />,
        <MenuItem
          onClick={() => closeMenu()}
          to="docs"
          title="Docs"
          icon={Icons.Docs}
        />,
      ]}
      {permissions === 'user' && [
        <MenuItem
          onClick={() => closeMenu()}
          to="dashboard"
          title="Dashboard"
          icon={Icons.Dashboard}
        />,
        // <MenuItem
        //   onClick={() => closeMenu()}
        //   to="search"
        //   title="Smart Search"
        //   icon={Icons.Search}
        // />,
        <SearchMenu
          leftIcon={<BaseIcon src={Icons.Search} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <RequestMenu
          leftIcon={<BaseIcon src={Icons.Request} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <OrderMenu
          leftIcon={<BaseIcon src={Icons.Request} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        // <MenuItem
        //   onClick={() => closeMenu()}
        //   to="inventory"
        //   title="Inventory"
        //   icon={Icons.Inventory}
        // />,
        <InventoryMenu
          leftIcon={<BaseIcon src={Icons.Update} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        enable_street_turn === '1' ? (
          <InHouseMenu
            leftIcon={<BaseIcon src={Icons.arrowcurcle} className={cls.icon} />}
            onClick={onMenuClick}
            sidebarIsOpen
          />
        ) : (
          ''
        ),
        <MenuItem
          onClick={() => closeMenu()}
          to="report"
          title="Report"
          icon={Icons.Report}
        />,
        mptyTrackingEnable === '1' ? (
          <TerminalMenu
            leftIcon={<BaseIcon src={Icons.Ports} className={cls.icon} />}
            onClick={onMenuClick}
            sidebarIsOpen
          />
        ) : (
          ''
        ),
      ]}
    </div>
  );
};
