// in src/App.js
import React, { useEffect, useState, createContext, useContext } from 'react';
import { createHashHistory } from 'history';
import { Admin, Resource } from 'react-admin';
import { useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import SC from '@/screens';
import { Layout, LogOutButton } from '@/components/Layout';
import { authProvider, dataProvider, customRoutes, theme } from '@/Providers';
import createAdminStore from '@/actions/store';
import { checkUpdate, getInit, updateSamrtApp } from '@/actions';
import ReactGA from 'react-ga';
import UpdatePopup from './components/UpdatePopup';
import Pusher from 'pusher-js';

const PusherContext = createContext();
export const usePusher = () => useContext(PusherContext);

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_CODE;
ReactGA.initialize(TRACKING_ID, {
  titleCase: true,
  gaOptions: {
    userId: localStorage.userID,
    clientId: localStorage.userID,
  },
});

ReactGA.send('pageview');

getInit();
const history = createHashHistory();
export const store = createAdminStore({
  authProvider,
  dataProvider,
  history,
});

const AdminResources = [
  <Resource name="dashboard" list={SC.Dashboard} />,
  <Resource name="members" list={SC.Members} />,
  <Resource name="content" list={SC.AdminInventory} />,
  <Resource name="update" list={SC.Update} />,
  <Resource name="chat" list={SC.Chat} />,
  <Resource name="finance" list={SC.Finance} />,
  <Resource name="integration" list={SC.Integration} />,
  <Resource name="logs" list={SC.Logs} />,
];

const UserResources = [
  <Resource name="dashboard" list={SC.Dashboard} />,
  <Resource name="search" list={SC.Search} />,
  <Resource name="requests" list={SC.Request} />,
  <Resource name="orders" list={SC.Order} />,
  <Resource name="inventory" list={SC.Inventory} />,
  <Resource name="report" list={SC.Report} />,
  <Resource name="members" list={SC.Members} />,
];

export default () => {
  const [pusher, setPusher] = useState(null);
  const [channels, setChannels] = useState({});
  const user = useSelector((state) => state.reducer.user);
  const token = localStorage.getItem('token');
  const notify = useNotify();

  // console.log(user.id);

  useEffect(() => {
    // console.log(user.id);

    if (token && user?.id) {
      const pusherInstance = new Pusher('99eb7d52eed3f2c353da', {
        cluster: 'us2',
      });

      const privateChannel = pusherInstance.subscribe(
        `Private.User.${user.id}`
      );
      const publicChannel = pusherInstance.subscribe('public');

      setPusher(pusherInstance);
      setChannels({ privateChannel, publicChannel });

      privateChannel.bind('SomeEvent', (data) => {
        console.log('Private Event Data:', data);
        notify(`Private Event: ${data.message}`, 'info');
      });

      publicChannel.bind('PublicEvent', (data) => {
        console.log('Public Event Data:', data);
        notify(`Public Event: ${data.message}`, 'info');
      });

      return () => {
        privateChannel.unbind_all();
        privateChannel.unsubscribe();
        publicChannel.unbind_all();
        publicChannel.unsubscribe();
        pusherInstance.disconnect();
      };
    }
  }, [token, user, notify]);

  const [updateApp, setUpdateApp] = useState(false);
  const [updateNotification, setUpdateNotification] = useState({
    title: 'We Have a New Update Available!',
    message: '',
  });
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const checkUpdateApp = () => {
    if (token) {
      checkUpdate().then((res) => {
        if (res.update === 1) {
          setUpdateApp(true);
        }
        if (res.notification) {
          setUpdateNotification(res.notification);
        }
      });
    }
  };

  const update = () => {
    setLoadingUpdate(true);
    updateSamrtApp()
      .then(() => {
        setLoadingUpdate(false);
        notify('App Successfully Updated', 'success');
        window.location.reload(true);
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    checkUpdateApp();
  }, [token]);

  return (
    <PusherContext.Provider value={{ pusher, channels }}>
      {updateApp && (
        <UpdatePopup
          updateNotification={updateNotification}
          update={update}
          loadingUpdate={loadingUpdate}
        />
      )}
      <Admin
        theme={theme}
        authProvider={authProvider}
        customRoutes={customRoutes}
        dataProvider={dataProvider}
        loginPage={SC.Login}
        logoutButton={LogOutButton}
        layout={Layout}
        history={history}>
        {(permissions) =>
          permissions === 'admin' ? AdminResources : UserResources
        }
      </Admin>
    </PusherContext.Provider>
  );
};
