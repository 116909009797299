import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  header: {
    height: 70,
    backgroundColor: '#f8b100',
    justifyContent: 'center',
  },
  toolbar: {
    margin: '0',
    background: 'transparent',
    '@media (max-width:1160px)': {
      paddingRight: '15px',
      paddingLeft: '15px',
    },
  },
  spacer: {
    flex: 1,
  },
  linkCont: {
    marginLeft: 40,
    '@media (max-width:1160px)': {
      marginLeft: 20,
    },
  },
  link: {
    fontFamily: 'Averta light',
    textDecoration: 'none',
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 2.7,
    color: '#303030',
    transition: 'all .2s ease-in-out 0s',
    marginLeft: 15,
    '&:hover': {
      // fontWeight: 700,
      // fontSize: 22,
      // lineHeight: 2.3,
      color: 'gray',
    },
    '@media (max-width:1160px)': {
      fontSize: '16px',
    },
  },
  search: {
    fontFamily: 'Averta light',
    width: 313,
    height: 46.3,
    borderRadius: 5,
    backgroundColor: '#30303044',
    padding: '5px 15px 5px 25px',
    '& > input': {
      color: '#fff',
    },
    '@media (max-width:1160px)': {
      width: '220px',
      height: 40.3,
    },
    '@media (max-width:800px)': {
      width: '165px',
      height: 35.3,
      padding: '5px 10px 5px 10px',
    },
    '@media (max-width:600px)': {
      display: 'none',
    },
  },
  searchIcon: {
    width: 30,
    color: '#f8b100',
  },
  menuIcon: {
    ['@media (min-width:800px)']: {
      display: 'none',
    },
  },
  logo: {
    width: '175px',
    '@media (max-width:1160px)': {
      width: '150px !important',
    },
    '@media (max-width:800px)': {
      width: '125px !important',
    },
    '@media (max-width:600px)': {
      width: '100px !important',
    },
  },
});
