import React from 'react';
import { useNotify, Loading, Button, setSidebarVisibility } from 'react-admin';
import { GetAppRounded } from '@material-ui/icons';
import { Filter } from '@/components/Global';
import { ParseInventoryList } from '@/actions/ParseData';
import { noScroll, removeNoScroll } from '@/actions/Helper';
import {
  getInventory,
  delInventory,
  upInventory,
  importInventory,
  exportInventory,
  updateInventoriContainers,
  delContainer,
  getTableList,
} from '@/actions';
import Add from './Add';
import List from './List';
import Edit from './Edit';
import styles from './styles';
import Pagination from '@/components/pagination';
import { useSelector } from 'react-redux';
import Options from '@/components/Global/Options';
import Appointment from './Appointment';
import SetAppointment from './SetAppointment';

export default (props) => {
  const cls = styles();
  const notify = useNotify();
  const [state, setState] = React.useState({
    edit: null,
    appointment: null,
    appointmentSet: null,
    file: null,
  });
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [],
  });
  const [tableTheadTitle, settableTheadTitle] = React.useState([]);

  const getInventoryTableHeadList = () => {
    setLoading(true);

    getTableList('inventoryTable')
      .then((res) => {
        if (res.data?.settings?.data) {
          settableTheadTitle(res.data?.settings?.data);
        } else {
          settableTheadTitle([
            // { label: '#', flex: 0.4, "show":  true  },
            { label: 'Size', flex: 0.6, show: true, object: 'size' },
            { label: 'SSL', flex: 0.7, show: true, object: 'ssl' },
            {
              label: 'Container #',
              flex: 0.7,
              show: true,
              object: 'name',
            },
            {
              label: 'Chassis #',
              flex: 0.9,
              show: true,
              object: 'chases',
            },
            {
              label: 'Gated Out',
              flex: 0.9,
              show: true,
              object: 'gated_out',
            },
            {
              label: 'Gated In',
              flex: 0.9,
              show: true,
              object: 'gated_in',
            },
            { label: 'Empty Location', flex: 0.7, show: true, object: 'loc' },
            {
              label: 'Return Apt',
              flex: 0.8,
              show: true,
              object: 'return_appointment',
            },
            {
              label: 'Return Location',
              flex: 0.7,
              show: true,
              object: 'return_terminals',
            },
            {
              label: 'Dual Terminal',
              flex: 0.7,
              show: true,
              object: 'return_dual_terminals',
            },
            { label: 'LFD', flex: 0.7, show: true, object: 'last_free_day' },
            {
              label: 'Est. PerDiem',
              flex: 0.7,
              show: true,
              object: 'est_per_diem',
            },
            // { label: 'Disputable Days', flex: 0.7 , "show":  true, object: '' },
            // { label: 'Disputable Amount', flex: 0.7 , "show":  true, object: '' },
            {
              label: 'Chassis Type',
              flex: 0.6,
              show: true,
              object: 'own_chases',
            },
            {
              label: 'Available Date',
              flex: 0.9,
              show: true,
              object: 'date',
            },
            {
              label: 'Last Update',
              flex: 0.9,
              show: true,
              object: 'added_date',
            },
            // { label: 'Action', flex: 1.3 , "show":  true, object: '' },
          ]);
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getInventoryTableHeadList();
  }, []);

  const [loading, setLoading] = React.useState(true);
  const [DATA, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });
  const [options, setOptions] = React.useState({
    open: false,
    DATA: [],
  });

  const enable_empty_tracking = useSelector(
    (state) => state.reducer.user?.metas?.enable_empty_tracking?.value
  );
  const mptyTrackingEnable = enable_empty_tracking;

  const getInventoryList = () => {
    setLoading(true);
    getInventory({ page: pagination.currentPage })
      .then((res) => {
        let DATA = ParseInventoryList(res.data);
        let pages = [];
        for (let i = 1; i <= res.last_page; i++) {
          pages.push(i);
        }
        setPagination((prev) => {
          return {
            ...prev,
            pages,
          };
        });
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getInventoryListNotLoading = () => {
    getInventory({ page: pagination.currentPage })
      .then((res) => {
        let DATA = ParseInventoryList(res.data);
        let pages = [];
        for (let i = 1; i <= res.last_page; i++) {
          pages.push(i);
        }
        setPagination((prev) => {
          return {
            ...prev,
            pages,
          };
        });
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getInventoryList();
  }, [pagination.currentPage]);

  React.useEffect(() => {
    if (state.file) {
      setLoading(true);
      importInventory(state.file)
        .then((res) => {
          let DATA = ParseInventoryList(res);
          setData(DATA);
          setFilter((f) => ({ ...f, DATA }));
          setLoading(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setData([]);
          setFilter((f) => ({ ...f, DATA: [] }));
          setLoading(false);
        });
    }
  }, [state.file, notify]);

  const Del = (id) =>
    delInventory(id)
      .then((msg) => {
        notify(msg);
        getInventory()
          .then((res) => {
            let DATA = ParseInventoryList(res.data);
            setData(DATA);
            setFilter({ ...filter, DATA });
            setLoading(false);
          })
          .catch((err) => notify(err, 'warning'));
      })
      .catch((err) => notify(err, 'warning'));

  const DelContainer = (id) =>
    delContainer(id)
      .then((msg) => {
        notify(msg);
        getInventory()
          .then((res) => {
            let DATA = ParseInventoryList(res.data);
            setData(DATA);
            setFilter({ ...filter, DATA });
            setLoading(false);
          })
          .catch((err) => notify(err, 'warning'));
      })
      .catch((err) => notify(err, 'warning'));

  const openPopUp = (item, containerId, gatedIn, gatedOut, container) => {
    noScroll();
    setState({
      ...state,
      edit: item,
      containerId: containerId,
      gatedIn: gatedIn,
      gatedOut: gatedOut,
      container,
    });
  };
  const openAppointment = (item, containerId, gatedIn, gatedOut, container) => {
    noScroll();
    setState({
      ...state,
      appointment: item,
      containerId: containerId,
      gatedIn: gatedIn,
      gatedOut: gatedOut,
      container,
    });
  };
  const openAppointmentSet = (
    item,
    containerId,
    gatedIn,
    gatedOut,
    container
  ) => {
    noScroll();
    setState({
      ...state,
      appointmentSet: item,
      containerId: containerId,
      gatedIn: gatedIn,
      gatedOut: gatedOut,
      container,
    });
  };

  const openOptions = (tableTheadTitle) => {
    noScroll();
    setState({
      ...state,
      tableTheadTitle: tableTheadTitle,
    });
  };

  // const Update = (form, inventory_id, containers) => {
  //   for (let el in form) {
  //     if (!form[el]) {
  //       notify('Can not add, Please check fields.', 'warning');
  //       return;
  //     }
  //   }

  const Update = (form, container_id, containers) => {
    for (let el in form) {
      // if (!form[el]) {
      //   notify('Can not add, Please check fields.', 'warning');
      //   return;
      // }
    }
    return upInventory(form)
      .then((res) => {
        notify('Updated Successfully.');
        getInventoryList();
        setFilter({ ...filter, DATA });
        closePopup();
        // updateInventoriContainers(container_id, { containers }).then(() => {
        // });
      })
      .catch((err) => notify(err, 'warning'));
  };

  const closePopup = () => {
    removeNoScroll();
    setState({ ...state, edit: null });
  };
  const closeAppointment = () => {
    removeNoScroll();
    setState({ ...state, appointment: null });
  };
  const closeAppointmentSet = () => {
    removeNoScroll();
    setState({ ...state, appointmentSet: null });
  };

  const handleExport = () => {
    setLoading(true);
    exportInventory()
      .then((res) => {
        setLoading(false);
        window.open(res, '_blank');
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  return (
    <div id="inventoryEmpty" className={cls.content}>
      {state.edit && (
        <Edit
          data={state.edit}
          containerId={state.containerId}
          gatedIn={state.gatedIn}
          gatedOut={state.gatedOut}
          container={state.container}
          Close={closePopup}
          Update={Update}
        />
      )}
      {state.appointment && (
        <Appointment
          data={state.appointment}
          // dataSet={state.appointmentSet}
          containerId={state.containerId}
          gatedIn={state.gatedIn}
          openAppointmentSet={openAppointmentSet}
          gatedOut={state.gatedOut}
          container={state.container}
          CloseAppointment={closeAppointment}
          // CloseAppointmentSet={closeAppointmentSet}
          Update={Update}
        />
      )}
      {state.appointmentSet && (
        <SetAppointment
          dataSet={state.appointmentSet}
          containerId={state.containerId}
          gatedIn={state.gatedIn}
          gatedOut={state.gatedOut}
          container={state.container}
          CloseAppointmentSet={closeAppointmentSet}
          Update={Update}
        />
      )}

      <div className={cls.cardHeader}>
        <span className={cls.title}>Add Inventory</span>
        <div className={cls.row}>
          <span className={cls.title}>Import</span>
          <label className={cls.import}>
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                setState({ ...state, file: e.target.files[0] });
                e.target.value = null;
              }}
            />
            <GetAppRounded />
            <span>XLSX</span>
          </label>
        </div>
      </div>

      <Add
        notify={notify}
        getInventoryList={getInventoryList}
        setData={(res) => {
          let DATA = ParseInventoryList(res);
          setData(DATA);
          setFilter({ ...filter, DATA });
        }}
      />
      <span className={cls.tableTopActions}>
        <Filter
          title="Your Empty"
          filter={filter}
          setFilter={setFilter}
          DATA={DATA}>
          <span className={cls.title}>Export</span>
          <span className={cls.titleBlue} onClick={handleExport}>
            XLSX
          </span>

          <Options
            options={options}
            tableType={'inventoryTable'}
            tableTheadTitle={tableTheadTitle}
            setOptions={setOptions}></Options>
        </Filter>
      </span>
      {loading ? (
        <Loading />
      ) : (
        <List
          DATA={filter.DATA}
          Del={Del}
          DelContainer={DelContainer}
          Edit={openPopUp}
          Appointment={openAppointment}
          openAppointmentSet={openAppointmentSet}
          getInventoryList={getInventoryList}
          getInventoryListNotLoading={getInventoryListNotLoading}
          currentPage={pagination.currentPage}
          mptyTrackingEnable={mptyTrackingEnable}
          tableTheadTitle={tableTheadTitle}
        />
      )}
      <Pagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
};
