// in src/MyAppBar.js
import React, { Children, cloneElement, isValidElement, useState } from 'react';
import { Button, Menu, Avatar } from '@material-ui/core';
import { ArrowDropDownRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import styles from './styles';
import { ListItemIcon, MenuItem, makeStyles } from '@material-ui/core';
import { PersonRounded, LogoutIcon } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { SaveData } from '@/actions';

export default (props) => {
  const user = useSelector((state) => state.reducer.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const useStyles = makeStyles(
    (theme) => ({
      icon: { minWidth: theme.spacing(5) },
      link: {
        textDecoration: 'none',
        color: theme.palette.text.secondary,
        display: 'flex',
      },
    }),
    { name: 'RaLogout' }
  );

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('token_admin');
    history.push('/login');
    SaveData({ user: { name: '' } });
  };

  const { children, logout } = props;
  if (!logout && !children) return null;

  const handleMenu = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const cls = styles();
  const cls1 = useStyles(props);

  return (
    <div>
      <Button
        onClick={handleMenu}
        className={cls.userName}
        // endIcon={<ArrowDropDownRounded />}
      >
        <div className={cls.userNameDiv}>
          <Avatar className={cls.avatar} src={user.image} alt={user.name} />
          {/* <span className={cls.userNameSpan}> */}
          <span className={cls.name}>{user.name}</span>
          {/* <ArrowDropDownRounded /> */}
          {/* </span> */}
          {/* <span className={cls.name}>Me</span> */}
        </div>
      </Button>
      <Menu
        elevation={0}
        keepMounted
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {/* {Children.map(children, (menuItem) =>
          isValidElement(menuItem)
            ? cloneElement(menuItem, { onClick: handleClose })
            : null
        )} */}
        <MenuItem onClick={handleClose}>
          <Link to="/profile" className={cls1.link}>
            <ListItemIcon className={cls1.icon}>
              <PersonRounded />
            </ListItemIcon>
            Profile
          </Link>
        </MenuItem>
        <MenuItem className={cls1.link} onClick={() => handleLogout()}>
          <ListItemIcon className={cls1.icon}>
            <ExitToAppOutlinedIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};
