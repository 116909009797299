import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  profileCont: {
    display: 'flex',
  },
  avatar: {
    width: 30,
    height: 30,
    // marginRight: 8,
  },
  userName: {
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 'normal',
    color: '#ffffff',
    padding: 5,
    fontFamily: 'Averta',
    textTransform: 'none',
  },
  userNameDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  userNameSpan: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    '@media (max-width:800px)': {
      display: 'none',
    },
  },
});
