import React, { useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { _get } from '@/actions/Helper';
import styles from './appointmentStyle';
import { TerminalsIcon } from '@/assets';

export default ({
  CloseAppointment,
  openAppointmentSet,
  data,
  dataSet,
  containerId,
  gatedIn,
  gatedOut,
  container,
}) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const [appointmentSet, setAppointmentSet] = React.useState(false);
  const Sizes = useSelector((state) => state.reducer.size);
  const SSLs = useSelector((state) => state.reducer.ssl);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const parseData = ({ id, size, ssl, date, count_all, address }) => ({
    id: containerId,
    date,
    count: count_all,
    size: Sizes.find(({ label }) => label === size),
    ssl: SSLs.find(({ label }) => label === ssl),
    loc: {
      '& svg': {
        fill: '#f8b100',
      },
      label: _get(address, 'address', ''),
      value: {
        lng: _get(address, 'long', ''),
        lat: _get(address, 'lat', ''),
        address: _get(address, 'address', ''),
        city: _get(address, 'city', ''),
      },
    },
    gated_in: gatedIn,
    gated_out: gatedOut,
    container_name: container.name,
    chasses_name: container.chases,
  });
  const todayy = new Date();
  const formattedSelectedDate = formatDate(selectedDate);
  // console.log('dataSet', dataSet);

  function getCurrentWeekDates(daydate) {
    const today = new Date();

    // Adjust for Monday-based weeks: getDay() returns 0 for Sunday, 1 for Monday, etc.
    const currentDay = (daydate.getDay() + 6) % 7; // Adjust so Monday is 0 and Sunday is 6

    const firstDayOfWeek = new Date(daydate);
    firstDayOfWeek.setDate(daydate.getDate() - currentDay);

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      dates.push(date);
    }
    return dates;
  }
  const Terminals = {
    EVERPORT: {
      id: 3,
      uuid: 'd1fbbb5a-ee9c-11ee-a8a1-960002e13c15',
      port: 'LA',
      name: 'Everport Terminal',
      gate_schedules: {
        '2024-12-09': {
          id: 1448,
          date: '2024-12-09',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-07T00:04:57.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-010': {
          id: 1526,
          date: '2024-12-010',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-11': {
          id: 1527,
          date: '2024-12-11',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-012': {
          id: 1528,
          date: '2024-12-012',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-13': {
          id: 1529,
          date: '2024-12-13',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-14': {
          id: 1530,
          date: '2024-12-14',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-15': {
          id: 1531,
          date: '2024-12-15',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
      },

      slug: 'EVERPORT',
      links: {
        restriction: 'https://www.etslink.com/',
        gate_schedule: 'https://www.etslink.com/',
        vessel_schedule: 'https://www.etslink.com/',
      },
    },
    Trapac: {
      id: 7,
      uuid: 'd1fd3ac0-ee9c-11ee-a104-960002e13c15',
      port: 'LA',
      name: 'Trapac',
      pier: null,
      gate_schedules: {
        '2024-12-06': {
          id: 1448,
          date: '2024-12-09',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-07T00:04:57.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-09': {
          id: 1448,
          date: '2024-12-09',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-07T00:04:57.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-010': {
          id: 1526,
          date: '2024-12-010',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-11': {
          id: 1527,
          date: '2024-12-11',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-012': {
          id: 1528,
          date: '2024-12-012',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-13': {
          id: 1529,
          date: '2024-12-13',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-14': {
          id: 1530,
          date: '2024-12-14',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-15': {
          id: 1531,
          date: '2024-12-15',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
      },

      slug: 'Trapac',
      links: {
        restriction: 'https://losangeles.trapac.com/empty-returns/',
        gate_schedule: 'https://losangeles.trapac.com/',
        vessel_schedule: 'https://losangeles.trapac.com/',
      },
    },
    WBCT: {
      id: 8,
      uuid: 'd1fd8746-ee9c-11ee-8eb0-960002e13c15',
      port: 'LA',
      name: 'West Basin Container Terminal',
      pier: null,
      gate_schedules: {
        '2024-12-06': {
          id: 1448,
          date: '2024-12-09',
          shift1: {
            single: 2,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-07T00:04:57.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-09': {
          id: 1448,
          date: '2024-12-09',
          shift1: {
            single: 2,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-07T00:04:57.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-010': {
          id: 1526,
          date: '2024-12-010',
          shift1: {
            single: 0,
            dual: 2,
          },
          shift2: {
            single: 2,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-11': {
          id: 1527,
          date: '2024-12-11',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-012': {
          id: 1528,
          date: '2024-12-012',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-13': {
          id: 1529,
          date: '2024-12-13',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-14': {
          id: 1530,
          date: '2024-12-14',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-15': {
          id: 1531,
          date: '2024-12-15',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
      },

      slug: 'WBCT',
      links: {
        restriction:
          'https://tosportal.portsamerica.com/logon?siteId=WBCT_LA&_=638472983417623660',
        gate_schedule:
          'https://tosportal.portsamerica.com/logon?siteId=WBCT_LA&_=638472983417623660',
        vessel_schedule:
          'https://tosportal.portsamerica.com/logon?siteId=WBCT_LA&_=638472983417623660',
      },
    },
    ITS: {
      id: 11,
      uuid: 'd1fe86d2-ee9c-11ee-85cf-960002e13c15',
      port: 'LB',
      name: 'International Transportation Services',
      pier: 'Pier G',
      gate_schedules: {
        '2024-12-06': {
          id: 1448,
          date: '2024-12-09',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-07T00:04:57.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-09': {
          id: 1448,
          date: '2024-12-09',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-07T00:04:57.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-010': {
          id: 1526,
          date: '2024-12-010',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 2,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-11': {
          id: 1527,
          date: '2024-12-11',
          shift1: {
            single: 2,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-012': {
          id: 1528,
          date: '2024-12-012',
          shift1: {
            single: 2,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-13': {
          id: 1529,
          date: '2024-12-13',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-14': {
          id: 1530,
          date: '2024-12-14',
          shift1: {
            single: 2,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
        '2024-12-15': {
          id: 1531,
          date: '2024-12-15',
          shift1: {
            single: 0,
            dual: 1,
          },
          shift2: {
            single: 0,
            dual: 1,
          },
          shift3: null,
          admin_moderations: null,
          created_at: '2024-11-13T23:46:20.000000Z',
          updated_at: '2024-11-30T23:04:35.000000Z',
        },
      },

      slug: 'ITS',
      links: {
        restriction: 'https://www.itslb.com/empty-receiving-updates/',
        gate_schedule: 'https://www.itslb.com/gate-hours-of-operation/',
        vessel_schedule: 'https://www.itslb.com/vessel-cutoff-2/',
      },
    },
  };

  function formatDate(date, format) {
    const day = date.getDate().toString().padStart(2, '0');
    const dayNumber = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    if (format == 1) {
      return `${year}-${monthNumber}-${day}`;
    } else {
      return { day, dayNumber, dayName, monthNumber, month, year };
    }
  }
  const weekDates = getCurrentWeekDates(selectedDate);
  const formattedDates = weekDates.map((date) => formatDate(date));

  const [containers, setContainers] = React.useState(data.containers);

  const [form, setFormData] = React.useState({
    id: null,
    gated_in: container.gated_in,
    gated_out: container.gated_out,
    container_name: container.name,
    chasses_name: container.chases,
    size: null,
    ssl: null,
    date: null,
    count: 0,
    loc: null,
  });

  React.useEffect(() => {
    setFormData(parseData(data));
  }, [data, Sizes, SSLs]);

  const setForm = (it, value) => setFormData({ ...form, [it]: value });

  const handleUpdateContainers = (name, value, index) => {
    setContainers((prev) => {
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const submit = () => {
    // setLoading(true);
    CloseAppointment();
    // Update(form, containerId, containers).then(() => setLoading(false));
  };
  const showAppts = (i) => {
    openAppointmentSet(
      containerId,
      container.id,
      container.gated_in,
      container.gated_out,
      container
    );
    // setLoading(true);
    // CloseAppointment();
    setAppointmentSet(true);
    // Update(form, containerId, containers).then(() => setLoading(false));
  };

  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    // Check if both hours and minutes are 00
    if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  const getInventoryContainers = () => {
    let inventoryContainers = [];
    data.containers.forEach((container) => {
      // console.log('con', container);
      // console.log('ff', form);

      // if (container.status) {
      inventoryContainers.push({
        size: data.size,
        ssl: data.ssl,
        location: data.loc,
        lfd: formatDateTimeToUTC(container.last_free_day),
        aptType: 'None',
        chassesType:
          container.own_chases === 0
            ? 'Pool'
            : container.own_chases === 1
            ? 'Own'
            : '',
        terminalApt: container.return_appointment || 'No Apt.',
        name: container.name ? container.name : '',
        id: container.id ? container.id : null,
        chases: container.chases ? container.chases : '',
        status: container.status ? container.status : null,
        gated_in: container.gated_in
          ? formatDateTimeToUTC(container.gated_in)
          : null,
        gated_out: container.gated_out
          ? formatDateTimeToUTC(container.gated_out)
          : null,
      });
      // }
    });
    setContainers(inventoryContainers);
  };

  useEffect(() => {
    getInventoryContainers();
  }, []);

  return (
    <div
      id="app_modal"
      onClick={(e) => e.target.id === 'app_modal' && CloseAppointment()}
      className={cls.popup}>
      <div className={cls.card}>
        {loading ? (
          <Loading className={cls.loading} />
        ) : (
          <>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded className={cls.closeP} onClick={CloseAppointment} />
            </Tooltip>
            <table style={{ marginBottom: '30px' }} className={cls.list}>
              <thead>
                <tr className={cls.thead}>
                  <th style={{ flex: 1 }}>Size</th>
                  <th style={{ flex: 1 }}>SSL</th>
                  <th style={{ flex: 1 }}>Container</th>
                  <th style={{ flex: 1 }}>Chassis</th>
                  <th style={{ flex: 1 }}>Gated Out</th>
                  <th style={{ flex: 1 }}>Empty Location</th>
                  <th style={{ flex: 1 }}>LFD</th>
                  <th style={{ flex: 1 }}>Chassis Type</th>
                  <th style={{ flex: 1 }}>Apt. Type</th>
                  <th style={{ flex: 1 }}>Terminal Apt.</th>
                </tr>
              </thead>
              <tbody className={cls.tbody}>
                {containers.map((container, index) => {
                  return (
                    <>
                      {container.id === containerId ? (
                        <tr
                          style={{ border: '1px solid #e9e9e9' }}
                          className={cls.tableRow}>
                          <td style={{ flex: 1 }}>{container.size}</td>
                          <td style={{ flex: 1 }}>{container.ssl}</td>
                          <td style={{ flex: 1 }}>{container.name}</td>
                          <td style={{ flex: 1 }}>{container.chases}</td>
                          <td style={{ flex: 1 }}>{container.gated_out}</td>
                          <td style={{ flex: 1 }}>{container.location}</td>
                          <td style={{ flex: 1 }}>{container.lfd}</td>
                          <td style={{ flex: 1 }}>{container.chassesType}</td>
                          <td style={{ flex: 1 }}>{container.aptType}</td>
                          <td style={{ flex: 1 }}>{container.terminalApt}</td>
                        </tr>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>

            {loading ? (
              <Loading />
            ) : (
              <table className={cls.list}>
                <span>
                  <thead className={cls.thead}>
                    {/* <td style={{ flex: 0.35 }}>{idx + 1 + (currentPage - 1) * 10}</td> */}
                    <span style={{ flex: 0.133 }} className={cls.shifttd}>
                      <th style={{ flex: 0.3 }}>Date</th>
                    </span>
                    {Object.keys(formattedDates).map((idx) => (
                      <span style={{ flex: 0.2 }} className={cls.shifttd}>
                        <th style={{ flex: 0.3 }}>
                          <div
                            id="datediv"
                            className={
                              formattedSelectedDate.dayNumber ==
                                formattedDates[idx].dayNumber &&
                              formattedSelectedDate.monthNumber ==
                                formattedDates[idx].monthNumber
                                ? cls.tableDateShowToday
                                : cls.tableDateShow
                            }>
                            <span
                              className={
                                formattedSelectedDate.dayNumber ==
                                  formattedDates[idx].dayNumber &&
                                formattedSelectedDate.monthNumber ==
                                  formattedDates[idx].monthNumber
                                  ? cls.dayNameToday
                                  : cls.dayName
                              }>
                              {formattedDates[idx].dayName}
                            </span>
                            <span
                              className={
                                formattedSelectedDate.dayNumber ==
                                  formattedDates[idx].dayNumber &&
                                formattedSelectedDate.monthNumber ==
                                  formattedDates[idx].monthNumber
                                  ? cls.dayNumberToday
                                  : cls.dayNumberNormal
                              }>
                              {formattedDates[idx].dayNumber}
                            </span>
                            <span
                              className={
                                formattedSelectedDate.dayNumber ==
                                  formattedDates[idx].dayNumber &&
                                formattedSelectedDate.monthNumber ==
                                  formattedDates[idx].monthNumber
                                  ? cls.dayNameToday
                                  : cls.dayName
                              }>
                              {formattedDates[idx].month}
                            </span>
                          </div>
                        </th>
                      </span>
                    ))}
                  </thead>
                </span>
                <span>
                  <thead className={cls.thead}>
                    <span style={{ flex: 0.2 }} className={cls.shifttd}>
                      <th style={{ flex: 0.3 }}>Terminal</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                  </thead>
                </span>
                <span>
                  <thead className={cls.thead}>
                    <span style={{ flex: 0.2 }} className={cls.shifttd}>
                      <th style={{ flex: 0.3 }}>Mode</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                  </thead>
                </span>
                <tbody className={cls.tbody}>
                  {Object.keys(Terminals).map((idx) => (
                    <tr
                      style={
                        idx === 'ITS' ? { border: '1px solid #e9e9e9' } : {}
                      }
                      className={
                        Terminals[idx].blur_status === 1
                          ? cls.tableRowNull
                          : cls.tableRow
                      }
                      key={idx}>
                      <span
                        style={{ flex: 0.2 }}
                        className={
                          Terminals[idx].blur_status === 1
                            ? cls.shifttdTerminal
                            : cls.shifttd
                        }>
                        <td className={cls.terminalsNameLogo}>
                          <a
                            className={cls.terminalLink}
                            target="_blank"
                            href={Terminals[idx].links?.gate_schedule}>
                            <img
                              width={80}
                              src={
                                idx == 'APM'
                                  ? TerminalsIcon.APM
                                  : idx == 'FMS'
                                  ? TerminalsIcon.FMS
                                  : idx == 'EVERPORT'
                                  ? TerminalsIcon.EVERPORT
                                  : idx == 'LACT'
                                  ? TerminalsIcon.LACT
                                  : idx == 'PCT'
                                  ? TerminalsIcon.PCT
                                  : idx == 'PST'
                                  ? TerminalsIcon.PST
                                  : idx == 'Trapac'
                                  ? TerminalsIcon.Trapac
                                  : idx == 'WBCT'
                                  ? TerminalsIcon.WBCT
                                  : idx == 'WCC'
                                  ? TerminalsIcon.WCC
                                  : idx == 'YTI'
                                  ? TerminalsIcon.YTI
                                  : idx == 'TTI'
                                  ? TerminalsIcon.TTI
                                  : idx == 'ITS'
                                  ? TerminalsIcon.ITS
                                  : idx == 'LBCT'
                                  ? TerminalsIcon.LBCT
                                  : idx == 'MATSON'
                                  ? TerminalsIcon.MATSON
                                  : idx == 'SSA MARINE'
                                  ? TerminalsIcon.SSA
                                  : idx == 'SSA STL'
                                  ? TerminalsIcon.STL
                                  : idx == 'PTS'
                                  ? TerminalsIcon.PTS
                                  : ''
                              }
                            />
                            {Terminals[idx].slug}
                          </a>
                        </td>
                      </span>
                      {Object.keys(formattedDates).map((i) => (
                        <span
                          style={{ flex: 0.3 }}
                          className={
                            formattedSelectedDate.dayNumber ==
                              formattedDates[i].dayNumber &&
                            formattedSelectedDate.monthNumber ==
                              formattedDates[i].monthNumber
                              ? cls.shifttdToday
                              : cls.shifttd
                          }>
                          {Terminals[idx].gate_schedules[
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                          ]
                            ? ''
                            : ''}
                          <th
                            className={
                              formattedDates[i].dayName === 'Sun'
                                ? cls.close
                                : formattedDates[i].dayName === 'Sat'
                                ? cls.close
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift1?.single === 0
                                ? cls.close
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift1?.single === 1
                                ? cls.open
                                : cls.zero
                            }
                            onClick={() =>
                              formattedDates[i].dayName === 'Sun'
                                ? ''
                                : formattedDates[i].dayName === 'Sat'
                                ? ''
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift1?.single === 1
                                ? showAppts()
                                : ''
                            }>
                            {formattedDates[i].dayName === 'Sun'
                              ? 'Closed'
                              : formattedDates[i].dayName === 'Sat'
                              ? 'Closed'
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1?.single === 0
                              ? 'Closed'
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1?.single === 1
                              ? 'Open Avail +1'
                              : 'Open Avail 0'}
                          </th>
                          <th
                            className={
                              formattedDates[i].dayName === 'Sun'
                                ? cls.close
                                : formattedDates[i].dayName === 'Sat'
                                ? cls.close
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift1?.dual === 0
                                ? cls.close
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift1?.dual === 1
                                ? cls.open
                                : cls.zero
                            }
                            onClick={() =>
                              formattedDates[i].dayName === 'Sun'
                                ? ''
                                : formattedDates[i].dayName === 'Sat'
                                ? ''
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift1?.dual === 1
                                ? showAppts()
                                : ''
                            }>
                            {formattedDates[i].dayName === 'Sun'
                              ? 'Closed'
                              : formattedDates[i].dayName === 'Sat'
                              ? 'Closed'
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1?.dual === 0
                              ? 'Closed'
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1?.dual === 1
                              ? 'Open Avail +1'
                              : 'Open Avail 0'}
                          </th>
                          <th
                            className={
                              formattedDates[i].dayName === 'Sun'
                                ? cls.close
                                : formattedDates[i].dayName === 'Sat'
                                ? cls.close
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift2?.single === 0
                                ? cls.close
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift2?.single === 1
                                ? cls.open
                                : cls.zero
                            }
                            onClick={() =>
                              formattedDates[i].dayName === 'Sun'
                                ? ''
                                : formattedDates[i].dayName === 'Sat'
                                ? ''
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift2?.single === 1
                                ? showAppts()
                                : ''
                            }>
                            {formattedDates[i].dayName === 'Sun'
                              ? 'Closed'
                              : formattedDates[i].dayName === 'Sat'
                              ? 'Closed'
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2?.single === 0
                              ? 'Closed'
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2?.single === 1
                              ? 'Open Avail +1'
                              : 'Open Avail 0'}
                          </th>
                          <th
                            className={
                              formattedDates[i].dayName === 'Sun'
                                ? cls.close
                                : formattedDates[i].dayName === 'Sat'
                                ? cls.close
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift2?.dual === 0
                                ? cls.close
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift2?.dual === 1
                                ? cls.open
                                : cls.zero
                            }
                            onClick={() =>
                              formattedDates[i].dayName === 'Sun'
                                ? ''
                                : formattedDates[i].dayName === 'Sat'
                                ? ''
                                : Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift2?.dual === 1
                                ? showAppts(i)
                                : ''
                            }>
                            {formattedDates[i].dayName === 'Sun'
                              ? 'Closed'
                              : formattedDates[i].dayName === 'Sat'
                              ? 'Closed'
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2?.dual === 0
                              ? 'Closed'
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2?.dual === 1
                              ? 'Open Avail +1'
                              : 'Open Avail 0'}
                          </th>
                        </span>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Button className={cls.but} onClick={() => CloseAppointment()}>
              Cancel Booking
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
