import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import stylesOp from './optionsStyles';
import {
  Tooltip,
  IconButton,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';
// import { Button, Grid, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { getMatchInventory, getUnMatchInventory } from '@/actions';
import { useNotify } from 'react-admin';
import { Icons } from '@/assets';
import { ReactSVG } from 'react-svg';
import { withStyles } from '@material-ui/core/styles';
import Switchh from '@material-ui/core/Switch';
import { setUserSettings } from '@/actions';

export default ({
  DATA = [],
  EmptiesTableTheadTitle,
  ExportsTableTheadTitle,
  Del,
  Edit,
  getInventoryMatchList,
  currentPage,
}) => {
  const cls = styles();
  const clsOp = stylesOp();
  const [NEWDATA, setNEWDATA] = useState([]);
  const [loadingMatch, setLoadingMatch] = useState(false);
  const [hover, setHover] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState([]);
  const [savings, setSavings] = useState([]);
  const [newDataList, setNewDataList] = React.useState({
    matches: {},
    newData: {},
  });
  const [optionsEm, setOptionsEm] = React.useState({
    open: false,
    DATA: [],
  });
  const [optionsEx, setOptionsEx] = React.useState({
    open: false,
    DATA: [],
  });
  useEffect(() => {
    setNEWDATA(DATA);
  }, [DATA]);

  const notify = useNotify();
  const formatPrice = (price) => {
    if (price) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(price);
    } else {
      return '$0';
    }
  };

  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    if (!dateString) {
      return '-';
    }
    // Check if both hours and minutes are 00
    else if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  const getActivContainers = (inventory, target) => {
    if (inventory) {
      let activeContainers = [];
      let deactiveContainers = [];
      inventory.containers.forEach((container) => {
        if (container.status === 1) {
          activeContainers.push(container);
        } else if (container.status === 0) {
          deactiveContainers.push(container);
        }
      });

      if (deactiveContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };

  const handlematch = (id, type, item, inventoryId, exportId) => {
    if (id === selectedContainer?.selected?.id) {
      return setSelectedContainer([]);
    }
    if (id === selectedContainer?.selectedMatch?.id) {
      return setSelectedContainer((prevState) => ({
        ...prevState,
        selectedMatch: null,
      }));
    }

    if (selectedContainer?.selected?.type === 'em') {
      if (selectedContainer?.exports?.includes(id)) {
        notify('Empty Matched');
        setSelectedContainer((prevState) => ({
          ...prevState,
          empties: null,
          exports: null,
          exportId: { type: 'exportId', id: exportId ?? exportId },
          selectedMatch: { type: type, id: id },
        }));
        return true;
      }
    }
    if (selectedContainer?.selected?.type === 'ex') {
      if (selectedContainer?.empties?.includes(id)) {
        notify('Export Matched');
        setSelectedContainer((prevState) => ({
          ...prevState,
          empties: null,
          exports: null,
          inventoryId: { type: 'inventoryId', id: inventoryId ?? inventoryId },
          selectedMatch: { type: type, id: id },
        }));
      }
      return true;
    }

    if (id === selectedContainer?.selected) {
      setSelectedContainer(null);
    } else {
      const emptiesIds = Object.values(item?.empties).flatMap((em) =>
        Object.values(em?.containers).map((cn) => cn.id)
      );

      const exportsIds = Object.values(item?.exports).flatMap((ex) =>
        Object.values(ex?.bookings).map((bk) => bk.id)
      );

      setSelectedContainer({
        empties: emptiesIds,
        exports: exportsIds,
        selected: { type: type, id: id },
        inventoryId: { type: 'inventoryId', id: inventoryId },
        exportId: { type: 'exportId', id: exportId },
      });
      notify(
        `Now Select from ${
          selectedContainer?.selected?.type === 'em'
            ? 'Empties'
            : selectedContainer?.selected?.type === 'ex'
            ? 'Exports'
            : ''
        } List. OR for Cancel, reselect selected item`
      );
    }
  };

  useEffect(() => {
    // setLoadingMatch(false);
    handleMatchApi();
  }, [
    selectedContainer?.selected?.id !== undefined &&
      selectedContainer?.selectedMatch?.id !== undefined,
  ]);
  const handleMatchApi = (i) => {
    // setShareLink({ loading: i.id });
    setLoadingMatch(true);
    if (
      selectedContainer?.selected?.id !== undefined &&
      selectedContainer?.selectedMatch?.id !== undefined
    ) {
      getMatchInventory(selectedContainer)
        .then((res) => {
          getInventoryMatchList();
          setSelectedContainer([]);
          setLoadingMatch(false);
        })
        .catch((err) => {
          notify(err, 'warning');
        });
    }
  };

  const handleUnMatchApi = (cnid, bkid, inventoryId, exportId) => {
    let req = {
      export_id: exportId,
      empty_id: inventoryId,
      booking_id: bkid,
      container_id: cnid,
    };
    setLoadingMatch(true);

    getUnMatchInventory(req)
      .then((res) => {
        getInventoryMatchList();
        setSelectedContainer([]);
        setLoadingMatch(false);
        notify('Unmatched');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };
  const extractMatches = (response) => {
    const matches = {};
    const newData = { list: {} };

    Object.keys(response).forEach((key, index) => {
      const { company_name, size_type, empties, exports, cabin } =
        response[key];

      const matchedBookings = new Set();
      const matchedBookingsa = new Set();
      const matchedContainers = new Set();
      const matchedContainersa = new Set();

      const containers = empties.flatMap((empty) =>
        empty.containers.map((container) => ({
          ...container,
          address: empty.address,
          saving: container.savings || {}, // Initialize saving if undefined
        }))
      );

      // Update bookings in exports with saving

      const updatedExports = exports.map((exp) => ({
        ...exp,
        bookings: exp.bookings.map((booking) => {
          const containerWithSaving = containers.find((container) => container);
          const bookingID = booking.id;
          return {
            ...booking,
            address: exp.address,
            saving: containerWithSaving?.savings
              ? { [bookingID]: containerWithSaving.savings[booking.id] }
              : null,
          };
        }),
      }));

      const matchArray = containers
        .map((container) => {
          const bookingIndex = updatedExports
            .flatMap((exp) => exp.bookings)
            .findIndex((booking) => booking.id === container.booking_id);

          if (bookingIndex !== -1) {
            const booking = updatedExports.flatMap((exp) => exp.bookings)[
              bookingIndex
            ];

            matchedBookings.add(booking.id);

            matchedBookingsa.add({
              ...booking,
              saving: container?.savings ? container.savings[booking.id] : null,
            });

            matchedContainers.add(container.id);
            matchedContainersa.add(container);
            return {
              booking,
              container,
            };
          }
          return null;
        })
        .filter(Boolean);

      if (matchArray.length > 0) {
        matches[index] = matchArray;
      }

      newData.list[index] = {
        company_name,
        size_type,
        cabin,
        empties,
        exports: updatedExports, // Use updated exports with saving
        matched: matchArray,
        matchedBookings: Array.from(matchedBookings),
        matchedBookingsa: Array.from(matchedBookingsa), // Convert set to array
        matchedContainers: Array.from(matchedContainers),
        matchedContainersa: Array.from(matchedContainersa),
      };
    });
    setNewDataList({ matches, newData });
    setNEWDATA(DATA);

    return {
      matches,
      newData,
    };
  };

  useEffect(() => {
    const result = extractMatches(DATA, setNewDataList);
    setNewDataList(result.newData.list);
    setLoadingMatch(false);
  }, [DATA]);

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getActivContainers, getInventoryMatchList]);

  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);

  const [tableWidth, setTableWidth] = useState(0);
  const [spanWidth, setSpanWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (amirbiosRef.current && amirbios1Ref.current) {
        const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;

        if (amirbiosTop <= 69) {
          amirbios2Ref.current.classList.add(cls.fix);
          tableRef.current.classList.add(cls.tableTopMargin);
        } else {
          amirbios2Ref.current.classList.remove(cls.fix);
          tableRef.current.classList.remove(cls.tableTopMargin);
        }
      }
      syncScroll('amirbios');
      setOptionsEx({ open: false });
      setOptionsEm({ open: false });
    };
    const updateWidth = () => {
      if (tableRef.current && amirbios1Ref.current) {
        const tableCurrentWidth =
          tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableCurrentWidth);
      }
    };

    const updateHeadWidth = () => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        const tableeCurrentWidth =
          amirbiosRef.current.getBoundingClientRect().width;
        setSpanWidth(tableeCurrentWidth);
      }
    };

    const syncScroll = (source) => {
      if (amirbiosRef.current || amirbios2Ref.current) {
        if (source === 'amirbios') {
          // console.log('sss');

          amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
        } else if (source === 'amirbios2') {
          // console.log('www');

          amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
        }
      }
    };

    updateWidth();
    updateHeadWidth();

    const handleScroll1 = () => syncScroll('amirbios');
    const handleScroll2 = () => syncScroll('amirbios2');

    if (amirbiosRef.current) {
      amirbiosRef.current.addEventListener('scroll', handleScroll1);
    }

    if (amirbios1Ref.current) {
      amirbios2Ref.current.addEventListener('scroll', handleScroll2);
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWidth);
    window.addEventListener('resize', updateHeadWidth);
    window.addEventListener('scroll', updateWidth);
    window.addEventListener('scroll', updateHeadWidth);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('resize', updateHeadWidth);
      window.removeEventListener('scroll', updateWidth);
      window.removeEventListener('scroll', updateHeadWidth);

      if (amirbiosRef.current) {
        amirbiosRef.current.removeEventListener('scroll', handleScroll1);
      }

      if (amirbios2Ref.current) {
        amirbios2Ref.current.removeEventListener('scroll', handleScroll2);
      }
    };
  }, [DATA, amirbiosRef, amirbios2Ref]);

  useEffect(() => {
    if (amirbios2Ref.current) {
      amirbios2Ref.current.style.width = `${spanWidth - 23}px`;
    }
  }, [spanWidth]);

  useEffect(() => {
    if (amirbios1Ref.current) {
      amirbios1Ref.current.style.width = `${tableWidth - 23}px`;
    }
  }, [tableWidth]);

  //Table titles seting function

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 35,
      height: 18,
      padding: 0,
      overflow: 'visible',
      margin: theme.spacing(0.8),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 26,
      backgroundColor: '#d5d5d5',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))((props) => <Switchh disableRipple {...props} />);

  const optionsEmRef = useRef(null);
  const optionsExRef = useRef(null);
  const [items, setItems] = useState([]);
  const [openedBox, setOpenedBox] = useState(null);
  const [changedList, setChangedList] = useState([...items]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [tableType, setTtableType] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClickOutside = (event) => {
    if (
      optionsEmRef.current &&
      !optionsEmRef.current.contains(event.target) &&
      optionsExRef.current &&
      !optionsExRef.current.contains(event.target)
    ) {
      setOptionsEm({ ...optionsEm, open: false });
      setOptionsEx({ ...optionsEx, open: false });
    }
  };
  console.log('hover', hover);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => handleClickOutside(e));
    return () =>
      document.removeEventListener('mousedown', (e) => handleClickOutside(e));
  }, [tableType, optionsEmRef, optionsExRef]);

  const saveUserSettings = (data) => {
    if (!changedList || changedList.length === 0) {
      const req = {
        key: tableType,
        data: null,
      };

      setUserSettings(req)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          console.log('posterr', err);
          notify(err, 'warning');
          setLoading(false);
        });

      return;
    }
    if (!changedList || changedList.length === 0) {
      notify('No Changes...');
      setLoading(false);

      return;
    }

    const req = {
      key: tableType,
      data: changedList,
    };

    setUserSettings(req)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log('posterr', err);
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const toggleItems = (type) => {
    setOpenedBox((prev) => (prev === type ? null : type));
    setTtableType(type);

    if (type === 'streetTurnEmptiesTable') {
      setOptionsEx({ open: false }); // بستن Exports
      setOptionsEm((prev) => ({ open: !prev.open })); // باز/بسته کردن Empties
    } else if (type === 'streetTurnExportsTable') {
      setOptionsEm({ open: false }); // بستن Empties
      setOptionsEx((prev) => ({ open: !prev.open })); // باز/بسته کردن Exports
    }

    const itemsToSet =
      type === 'streetTurnEmptiesTable'
        ? EmptiesTableTheadTitle
        : type === 'streetTurnExportsTable'
        ? ExportsTableTheadTitle
        : '';

    setItems(itemsToSet);
  };

  const handleDragStart = (index) => setDraggedItem(index);

  const handleDragOver = (index) => {
    if (draggedItem === index) return;

    const updatedItems = [...items];
    const dragged = updatedItems[draggedItem];
    updatedItems.splice(draggedItem, 1);
    updatedItems.splice(index, 0, dragged);

    setDraggedItem(index);
    setItems(updatedItems);
    setChangedList(updatedItems);
  };

  const toggleVisibility = (index) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, show: !item.show } : item
    );
    setItems(updatedItems);
    setChangedList(updatedItems);
  };

  const toggleVisibilityy = (index) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, show: !item.show } : item
    );
    setItems(updatedItems);
    setChangedList(updatedItems);
  };

  // useEffect(() => {
  //   if (optionsEm.open) {
  //     $('.optionsEm-card').slideDown();
  //   } else {
  //     $('.optionsEm-card').slideUp();
  //   }

  //   if (optionsEx.open) {
  //     $('.optionsEx-card').slideDown();
  //   } else {
  //     $('.optionsEx-card').slideUp();
  //   }
  // }, [optionsEm.open, optionsEx.open]);

  return (
    newDataList.empties ?? (
      <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
        {loadingMatch === true ? (
          <div className={cls.loadingMatchDiv}>
            <div className={cls.message}>
              <CircularProgress className={cls.icon} color="primary" />
              <h1>Loading...</h1>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className={cls.tableCentertDiv}>
          <table ref={tableRef} id="table" className={cls.listTableCenter}>
            <thead ref={amirbios2Ref} id="amirbios1">
              <span ref={amirbios1Ref} id="amirbios2">
                <span className={cls.thead}>
                  <th className={cls.tableThTop} style={{ flex: 0.1765 }}></th>
                  <th className={cls.tableThTop} style={{ flex: 1.008 }}>
                    <span className={cls.tableThTopTiltlesSpan}>
                      <ReactSVG src={Icons.importIcon} className={cls.icon} />
                      Your Empties
                    </span>
                    <div className={clsOp.filterContainer}>
                      <div className={clsOp.headerBar}>
                        <span className={clsOp.title}></span>
                        <div className={clsOp.rocw3}>
                          <div
                            className={clsOp.filterRow}
                            onClick={() =>
                              toggleItems('streetTurnEmptiesTable')
                            }>
                            <ReactSVG
                              src={Icons.options}
                              className={clsOp.filter}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        ref={optionsEmRef}
                        className={clsx(clsOp.card, 'optionsEm-card')}
                        style={{
                          display: optionsEm.open === true ? 'block' : 'none',
                        }}>
                        <Grid
                          className={clsOp.optionsBody}
                          container
                          spacing={2}>
                          <p className={clsOp.cardTitle}>Configure Columns</p>
                          <p className={clsOp.itemsTopTitle}>
                            Shown Attributes
                          </p>
                          <p className={clsOp.itemsTopTitle}>Your Empties</p>
                          <span className={clsOp.listSpan}>
                            {items?.map((header, index) => (
                              <span
                                key={index}
                                draggable
                                onDragStart={() => handleDragStart(index)}
                                onDragOver={() => handleDragOver(index)}
                                onDragEnd={() => setDraggedItem(null)}
                                className={clsOp.actionsSpan}>
                                <span className={clsOp.dragdropIconTitle}>
                                  <ReactSVG
                                    src={Icons.dragdrop}
                                    className={clsOp.dragdropIcon}
                                  />
                                  {header?.label}
                                </span>
                                <IOSSwitch
                                  checked={header?.show}
                                  onChange={() => toggleVisibility(index)}
                                  name="checkedVisibility"
                                />
                              </span>
                            ))}
                          </span>
                        </Grid>
                        <div className={clsOp.row2}>
                          {loading ? (
                            <div className={clsOp.loading}>
                              <CircularProgress />
                            </div>
                          ) : (
                            <span className={clsOp.btnsSpan}>
                              <Button
                                className={clsOp.but}
                                onClick={saveUserSettings}>
                                Save
                              </Button>
                              <Button
                                className={clsOp.but}
                                onClick={() => {
                                  setLoading(true);
                                  saveUserSettings('null');
                                }}>
                                Restore Default
                              </Button>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className={cls.tableThTop} style={{ flex: 0.6 }}>
                    <span className={cls.tableThTopTiltlesSpan}>
                      <ReactSVG src={Icons.importIcon} className={cls.icon} />
                      Your Exports
                    </span>
                    <div className={clsOp.filterContainer}>
                      <div className={clsOp.headerBar}>
                        <span className={clsOp.title}></span>
                        <div className={clsOp.rocw3}>
                          {/* {children} */}
                          <div
                            className={clsOp.filterRow}
                            onClick={() =>
                              toggleItems('streetTurnExportsTable')
                            }>
                            <ReactSVG
                              src={Icons.options}
                              className={clsOp.filter}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        ref={optionsExRef}
                        className={clsx(clsOp.card, 'optionsEx-card')}
                        style={{
                          display: optionsEx.open === true ? 'flex' : 'none',
                        }}>
                        <Grid
                          className={clsOp.optionsBody}
                          container
                          spacing={2}>
                          <p className={clsOp.cardTitle}>Configure Columns</p>
                          <p className={clsOp.itemsTopTitle}>
                            Shown Attributes
                          </p>
                          <p className={clsOp.itemsTopTitle}>Your Exports</p>
                          <span className={clsOp.listSpan}>
                            {items.map((header, index) => (
                              <span
                                key={index}
                                draggable
                                onDragStart={() => handleDragStart(index)}
                                onDragOver={() => handleDragOver(index)}
                                onDragEnd={() => setDraggedItem(null)}
                                className={clsOp.actionsSpan}>
                                <span className={clsOp.dragdropIconTitle}>
                                  <ReactSVG
                                    src={Icons.dragdrop}
                                    className={clsOp.dragdropIcon}
                                  />
                                  {header?.label}
                                </span>
                                <IOSSwitch
                                  checked={header?.show}
                                  onChange={() => toggleVisibilityy(index)}
                                  name="checkedVisibilityy"
                                />
                              </span>
                            ))}
                          </span>
                        </Grid>
                        <div className={clsOp.row2}>
                          {loading ? (
                            <div className={clsOp.loading}>
                              <CircularProgress />
                            </div>
                          ) : (
                            <span className={clsOp.btnsSpan}>
                              <Button
                                className={clsOp.but}
                                onClick={saveUserSettings}>
                                Save
                              </Button>
                              <Button
                                className={clsOp.but}
                                onClick={() => {
                                  setLoading(true);
                                  saveUserSettings('null');
                                }}>
                                Restore Default
                              </Button>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </th>
                </span>
                <span className={cls.thead}>
                  <td className={cls.tableTD} style={{ flex: 0.19 }}>
                    <tr className={cls.tableRowHeader}>
                      {/* <th className={cls.tableTh} style={{ flex: 0.25 }}>
                      #
                    </th> */}
                      <th className={cls.tableTh} style={{ flex: 1 }}>
                        Size
                      </th>
                      <th className={cls.tableTh} style={{ flex: 1 }}>
                        SSL
                      </th>
                    </tr>
                  </td>
                  <td className={cls.tableTD} style={{ flex: 1 }}>
                    <tr className={cls.tableRowHeader} style={{ flex: 1 }}>
                      {EmptiesTableTheadTitle.map((header, index) =>
                        header?.show === false ? (
                          ''
                        ) : (
                          <th
                            className={cls.tableTh}
                            key={index}
                            style={{ flex: header.flex }}>
                            {header.label}
                          </th>
                        )
                      )}
                    </tr>
                  </td>
                  <td className={cls.tableTD} style={{ flex: 0.6 }}>
                    <tr className={cls.tableRowHeader} style={{ flex: 1 }}>
                      {ExportsTableTheadTitle.map((header, index) =>
                        header?.show === false ? (
                          ''
                        ) : (
                          <th
                            className={cls.tableTh}
                            key={index}
                            style={{ flex: header.flex }}>
                            {header.label}
                          </th>
                        )
                      )}
                      <th className={cls.tableTh} style={{ flex: 1.1 }}>
                        Saving
                      </th>
                    </tr>
                  </td>
                </span>
              </span>
            </thead>
            <tbody className={cls.tbody}>
              {
                // newDataList.empties ??
                Object.values(NEWDATA).map((i, idx) =>
                  i?.empties[0]?.containers?.length === 0 ? (
                    ''
                  ) : (
                    <tr className={cls.tableRowMain} key={idx}>
                      <td
                        style={{
                          height:
                            Object.values(i?.exports || {}).reduce(
                              (sum, ex) => sum + (ex.bookings_count || 0),
                              0
                            ) <
                            Object.values(i?.empties || {}).reduce(
                              (sum, em) => sum + (em.containers_count || 0),
                              0
                            )
                              ? `${
                                  Object.values(i?.empties || {}).reduce(
                                    (sum, em) =>
                                      sum + (em.containers_count || 0),
                                    0
                                  ) *
                                    38 +
                                  Object.values(i?.empties || {}).reduce(
                                    (sum, em) =>
                                      sum + (em.containers_count || 0),
                                    0
                                  ) *
                                    10.9
                                }px`
                              : Object.values(i?.exports || {}).reduce(
                                  (sum, ex) => sum + (ex.bookings_count || 0),
                                  0
                                ) >
                                Object.values(i?.empties || {}).reduce(
                                  (sum, em) => sum + (em.containers_count || 0),
                                  0
                                )
                              ? `${
                                  Object.values(i?.exports || {}).reduce(
                                    (sum, ex) => sum + (ex.bookings_count || 0),
                                    0
                                  ) *
                                    38 +
                                  Object.values(i?.exports || {}).reduce(
                                    (sum, ex) => sum + (ex.bookings_count || 0),
                                    0
                                  ) *
                                    10.9
                                }px`
                              : '49px',
                          flex: 0.181,
                        }}
                        className={cls.tableTD}>
                        <tr style={{ flex: 0.25 }} className={cls.tableRowLeft}>
                          {/* <td className={cls.tableTDMain} style={{ flex: 1 }}>
                          {idx + 1 + (currentPage - 1) * 10}
                        </td> */}
                          <td className={cls.tableTDMain} style={{ flex: 1 }}>
                            {i.size_type}
                          </td>
                          <td className={cls.tableTDMain} style={{ flex: 1 }}>
                            {i.company_name}
                          </td>
                        </tr>
                      </td>
                      <td
                        style={{
                          height:
                            Object.values(i?.exports || {}).reduce(
                              (sum, ex) => sum + (ex.bookings_count || 0),
                              0
                            ) <
                            Object.values(i?.empties || {}).reduce(
                              (sum, em) => sum + (em.containers_count || 0),
                              0
                            )
                              ? `${
                                  Object.values(i?.empties || {}).reduce(
                                    (sum, em) =>
                                      sum + (em.containers_count || 0),
                                    0
                                  ) *
                                    38 +
                                  Object.values(i?.empties || {}).reduce(
                                    (sum, em) =>
                                      sum + (em.containers_count || 0),
                                    0
                                  ) *
                                    10.9
                                }px`
                              : Object.values(i?.exports || {}).reduce(
                                  (sum, ex) => sum + (ex.bookings_count || 0),
                                  0
                                ) >
                                Object.values(i?.empties || {}).reduce(
                                  (sum, em) => sum + (em.containers_count || 0),
                                  0
                                )
                              ? `${
                                  Object.values(i?.exports || {}).reduce(
                                    (sum, ex) => sum + (ex.bookings_count || 0),
                                    0
                                  ) *
                                    38 +
                                  Object.values(i?.exports || {}).reduce(
                                    (sum, ex) => sum + (ex.bookings_count || 0),
                                    0
                                  ) *
                                    10.9
                                }px`
                              : '49px',
                          flex: 1,
                        }}
                        className={cls.tableTD}>
                        {newDataList[idx]?.matchedContainersa.map(
                          (container, mchca) => (
                            <>
                              <tr
                                className={cls.tableRowMatched}
                                style={{ flex: 1 }}>
                                {EmptiesTableTheadTitle.map((header, index) => {
                                  const objectKey = header.object;

                                  return header?.show === false ? (
                                    ''
                                  ) : (
                                    <td
                                      key={`${index}new`}
                                      className={cls.tableTDMain}
                                      style={{ flex: header.flex }}>
                                      {objectKey === 'name'
                                        ? container.name
                                        : ''}
                                      {objectKey === 'chases'
                                        ? container.chases
                                        : ''}
                                      {objectKey === 'gated_out'
                                        ? formatDateTimeToUTC(
                                            container.gated_out
                                          )
                                        : ''}
                                      {objectKey === 'gated_in'
                                        ? formatDateTimeToUTC(
                                            container.gated_in
                                          )
                                        : ''}
                                      {objectKey === 'loc'
                                        ? container?.address?.city
                                        : ''}
                                      {objectKey === 'return_appointment'
                                        ? 'No Apt. Available'
                                        : ''}
                                      {objectKey === 'return_terminals'
                                        ? container.return_terminals
                                        : ''}
                                      {objectKey === 'est_per_diem'
                                        ? formatPrice(container.est_per_diem)
                                        : ''}
                                      {objectKey === 'lfd'
                                        ? formatDateTimeToUTC(
                                            container.last_free_day
                                          )
                                        : ''}
                                      {objectKey === 'dual'
                                        ? container.return_dual_terminals
                                        : ''}
                                    </td>
                                  );
                                })}
                              </tr>
                            </>
                          )
                        )}
                        {newDataList[idx]?.empties.map((em, idxx) =>
                          Object.values(em?.containers).map(
                            (container, idxxx) => (
                              <>
                                {newDataList[idx]?.matchedContainers.includes(
                                  container.id
                                ) ? (
                                  ''
                                ) : selectedContainer?.empties?.includes(
                                    container.id
                                  ) &&
                                  selectedContainer?.selected.type === 'ex' ? (
                                  <tr
                                    onMouseEnter={() => {
                                      setHover(true);
                                      setSavings(container.savings);
                                    }}
                                    onMouseLeave={() => {
                                      setHover(false);
                                    }}
                                    onClick={() =>
                                      handlematch(
                                        container.id,
                                        'em',
                                        i,
                                        container.inventory_id
                                      )
                                    }
                                    className={
                                      selectedContainer?.selectedMatch?.id ===
                                      container.id
                                        ? cls.tableRowSelected
                                        : selectedContainer?.empties?.includes(
                                            container.id
                                          ) &&
                                          selectedContainer?.selected.type ===
                                            'ex'
                                        ? cls.selectableItem
                                        : selectedContainer?.selected?.id ===
                                          container.id
                                        ? cls.tableRowSelected
                                        : cls.tableRow
                                    }
                                    style={{ flex: 1 }}>
                                    {EmptiesTableTheadTitle.map(
                                      (header, index) => {
                                        const objectKey = header.object;

                                        return header?.show === false ? (
                                          ''
                                        ) : (
                                          <td
                                            key={`${index}new`}
                                            className={cls.tableTDMain}
                                            style={{
                                              flex: header.flex,
                                            }}>
                                            {objectKey === 'name'
                                              ? container.name
                                              : ''}
                                            {objectKey === 'chases'
                                              ? container.chases
                                              : ''}
                                            {objectKey === 'gated_out'
                                              ? formatDateTimeToUTC(
                                                  container.gated_out
                                                )
                                              : ''}
                                            {objectKey === 'gated_in'
                                              ? formatDateTimeToUTC(
                                                  container.gated_in
                                                )
                                              : ''}
                                            {objectKey === 'loc'
                                              ? em?.address?.city
                                              : ''}
                                            {objectKey === 'return_appointment'
                                              ? 'No Apt. Available'
                                              : ''}
                                            {objectKey === 'return_terminals'
                                              ? container.return_terminals
                                              : ''}
                                            {objectKey === 'est_per_diem'
                                              ? formatPrice(
                                                  container.est_per_diem
                                                )
                                              : ''}
                                            {objectKey === 'lfd'
                                              ? formatDateTimeToUTC(
                                                  container.last_free_day
                                                )
                                              : ''}
                                            {objectKey === 'dual'
                                              ? container.return_dual_terminals
                                              : ''}
                                          </td>
                                        );
                                      }
                                    )}
                                  </tr>
                                ) : (
                                  <tr
                                    onClick={() =>
                                      handlematch(
                                        container.id,
                                        'em',
                                        i,
                                        container.inventory_id
                                      )
                                    }
                                    className={
                                      selectedContainer?.selectedMatch?.id ===
                                      container.id
                                        ? cls.tableRowSelected
                                        : selectedContainer?.empties?.includes(
                                            container.id
                                          ) &&
                                          selectedContainer?.selected.type ===
                                            'ex'
                                        ? cls.selectableItem
                                        : selectedContainer?.selected?.id ===
                                          container.id
                                        ? cls.tableRowSelected
                                        : cls.tableRow
                                    }
                                    style={{ flex: 1 }}>
                                    {EmptiesTableTheadTitle.map(
                                      (header, index) => {
                                        const objectKey = header.object;

                                        return header?.show === false ? (
                                          ''
                                        ) : (
                                          <td
                                            key={`${index}new`}
                                            className={cls.tableTDMain}
                                            style={{
                                              flex: header.flex,
                                            }}>
                                            {objectKey === 'name'
                                              ? container.name
                                              : ''}
                                            {objectKey === 'chases'
                                              ? container.chases
                                              : ''}
                                            {objectKey === 'gated_out'
                                              ? formatDateTimeToUTC(
                                                  container.gated_out
                                                )
                                              : ''}
                                            {objectKey === 'gated_in'
                                              ? formatDateTimeToUTC(
                                                  container.gated_in
                                                )
                                              : ''}
                                            {objectKey === 'loc'
                                              ? em?.address?.city
                                              : ''}
                                            {objectKey === 'return_appointment'
                                              ? 'No Apt. Available'
                                              : ''}
                                            {objectKey === 'return_terminals'
                                              ? container.return_terminals
                                              : ''}
                                            {objectKey === 'est_per_diem'
                                              ? formatPrice(
                                                  container.est_per_diem
                                                )
                                              : ''}
                                            {objectKey === 'lfd'
                                              ? formatDateTimeToUTC(
                                                  container.last_free_day
                                                )
                                              : ''}
                                            {objectKey === 'dual'
                                              ? container.return_dual_terminals
                                              : ''}
                                          </td>
                                        );
                                      }
                                    )}
                                  </tr>
                                )}
                              </>
                            )
                          )
                        )}
                      </td>
                      <td
                        style={{
                          height:
                            Object.values(i?.exports || {}).reduce(
                              (sum, ex) => sum + (ex.bookings_count || 0),
                              0
                            ) <
                            Object.values(i?.empties || {}).reduce(
                              (sum, em) => sum + (em.containers_count || 0),
                              0
                            )
                              ? `${
                                  Object.values(i?.empties || {}).reduce(
                                    (sum, em) =>
                                      sum + (em.containers_count || 0),
                                    0
                                  ) *
                                    38 +
                                  Object.values(i?.empties || {}).reduce(
                                    (sum, em) =>
                                      sum + (em.containers_count || 0),
                                    0
                                  ) *
                                    10.9
                                }px`
                              : Object.values(i?.exports || {}).reduce(
                                  (sum, ex) => sum + (ex.bookings_count || 0),
                                  0
                                ) >
                                Object.values(i?.empties || {}).reduce(
                                  (sum, em) => sum + (em.containers_count || 0),
                                  0
                                )
                              ? `${
                                  Object.values(i?.exports || {}).reduce(
                                    (sum, ex) => sum + (ex.bookings_count || 0),
                                    0
                                  ) *
                                    38 +
                                  Object.values(i?.exports || {}).reduce(
                                    (sum, ex) => sum + (ex.bookings_count || 0),
                                    0
                                  ) *
                                    10.9
                                }px`
                              : '49px',
                          flex: 0.0001,
                        }}
                        className={cls.tableTDMatchBtn}>
                        <tr
                          className={cls.tableRowUnmatchBtn}
                          style={{ flex: 1 }}>
                          {newDataList[idx]?.matchedContainersa.map(
                            (container, mchca) => (
                              <td
                                onClick={() =>
                                  handleUnMatchApi(
                                    newDataList[idx]?.matchedContainersa[mchca]
                                      .id,
                                    newDataList[idx]?.matchedBookingsa[mchca]
                                      .id,
                                    newDataList[idx]?.matchedContainersa[mchca]
                                      .inventory_id,
                                    newDataList[idx]?.matchedBookingsa[mchca]
                                      .export_id
                                  )
                                }
                                className={cls.tableTDMain}
                                style={{ flex: 1 }}>
                                <Tooltip arrow title="Unmatch" placement="top">
                                  <IconButton
                                    aria-label="unmatch"
                                    onClick={() =>
                                      handleUnMatchApi(
                                        newDataList[idx]?.matchedContainersa[
                                          mchca
                                        ].id,
                                        newDataList[idx]?.matchedBookingsa[
                                          mchca
                                        ].id,
                                        newDataList[idx]?.matchedContainersa[
                                          mchca
                                        ].inventory_id,
                                        newDataList[idx]?.matchedBookingsa[
                                          mchca
                                        ].export_id
                                      )
                                    }>
                                    <ReactSVG
                                      src={Icons.forbidden}
                                      className={cls.icon}
                                    />
                                  </IconButton>
                                </Tooltip>

                                {/* <Tooltip
                                  onClick={() =>
                                    handleUnMatchApi(
                                      newDataList[idx]?.matchedContainersa[
                                        mchca
                                      ].id,
                                      newDataList[idx]?.matchedBookingsa[mchca]
                                        .id,
                                      newDataList[idx]?.matchedContainersa[
                                        mchca
                                      ].inventory_id,
                                      newDataList[idx]?.matchedBookingsa[mchca]
                                        .export_id
                                    )
                                  }
                                  arrow
                                  title="Unmatch"
                                  placement="top"> */}
                                {/* <ReactSVG
                                  src={Icons.forbidden}
                                  className={cls.icon}
                                /> */}
                                {/* </Tooltip> */}
                              </td>
                            )
                          )}
                        </tr>
                      </td>
                      <td
                        style={{
                          height:
                            Object.values(i?.exports || {}).reduce(
                              (sum, ex) => sum + (ex.bookings_count || 0),
                              0
                            ) <
                            Object.values(i?.empties || {}).reduce(
                              (sum, em) => sum + (em.containers_count || 0),
                              0
                            )
                              ? `${
                                  Object.values(i?.empties || {}).reduce(
                                    (sum, em) =>
                                      sum + (em.containers_count || 0),
                                    0
                                  ) *
                                    38 +
                                  Object.values(i?.empties || {}).reduce(
                                    (sum, em) =>
                                      sum + (em.containers_count || 0),
                                    0
                                  ) *
                                    10.9
                                }px`
                              : Object.values(i?.exports || {}).reduce(
                                  (sum, ex) => sum + (ex.bookings_count || 0),
                                  0
                                ) >
                                Object.values(i?.empties || {}).reduce(
                                  (sum, em) => sum + (em.containers_count || 0),
                                  0
                                )
                              ? `${
                                  Object.values(i?.exports || {}).reduce(
                                    (sum, ex) => sum + (ex.bookings_count || 0),
                                    0
                                  ) *
                                    38 +
                                  Object.values(i?.exports || {}).reduce(
                                    (sum, ex) => sum + (ex.bookings_count || 0),
                                    0
                                  ) *
                                    10.9
                                }px`
                              : '49px',
                          flex: 0.589,
                        }}
                        className={cls.tableTD}>
                        {newDataList[idx]?.matchedBookingsa.map(
                          (booking, bk) => (
                            <>
                              {newDataList[idx]?.matchedBookings.includes(
                                booking.id
                              ) ? (
                                <tr
                                  className={cls.tableRowMatched}
                                  style={{ flex: 1 }}>
                                  {ExportsTableTheadTitle.map(
                                    (header, index) => {
                                      const objectKey = header.object;

                                      return header?.show === false ? (
                                        ''
                                      ) : (
                                        <td
                                          key={`${index}new`}
                                          className={cls.tableTDMain}
                                          style={{ flex: header.flex }}>
                                          {objectKey === 'booking'
                                            ? booking?.name
                                            : ''}
                                          {objectKey === 'name'
                                            ? booking?.container
                                            : ''}
                                          {objectKey === 'empty_released'
                                            ? formatDateTimeToUTC(
                                                booking?.empty_released
                                              )
                                            : ''}
                                          {objectKey === 'gated_in'
                                            ? formatDateTimeToUTC(
                                                booking?.gated_in
                                              )
                                            : ''}
                                          {objectKey === 'pickup_location'
                                            ? booking?.address?.city
                                            : ''}
                                          {objectKey === 'vessel'
                                            ? booking?.vessel_name
                                            : ''}
                                          {objectKey === 'voyage'
                                            ? booking?.vessel_voyage_in
                                            : ''}
                                          {objectKey === 'return_appointment'
                                            ? 'No Apt. Available'
                                            : ''}
                                          {objectKey === 'erd'
                                            ? formatDateTimeToUTC(booking?.erd)
                                            : ''}
                                          {objectKey === 'cutoff'
                                            ? formatDateTimeToUTC(
                                                booking?.cutoff
                                              )
                                            : ''}
                                          {objectKey === 'terminal_return'
                                            ? booking?.return_to_terminal?.slug
                                            : ''}
                                          {objectKey === 'vessel_departure'
                                            ? booking?.vessel_departure
                                            : ''}
                                        </td>
                                      );
                                    }
                                  )}
                                  <td
                                    className={cls.tableTDMain}
                                    style={{ flex: 1 }}>
                                    <span className={cls.savingSpan}>
                                      {formatPrice(booking.saving)}
                                      <ReactSVG
                                        src={Icons.dicsount}
                                        className={cls.dicsounticon}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        )}
                        {newDataList[idx]?.exports.map((ex, idx) =>
                          Object.values(ex?.bookings).map((booking, idx) => (
                            <>
                              {booking.container_id ? (
                                ''
                              ) : selectedContainer?.exports?.includes(
                                  booking.id
                                ) &&
                                selectedContainer?.selected.type === 'em' ? (
                                <tr
                                  onMouseEnter={() => {
                                    setHover(true);
                                    setSavings(booking.saving);
                                  }}
                                  onMouseLeave={() => setHover(false)}
                                  onClick={() =>
                                    handlematch(
                                      booking.id,
                                      'ex',
                                      i,
                                      null,
                                      booking.export_id
                                    )
                                  }
                                  className={
                                    selectedContainer?.selectedMatch?.id ===
                                    booking.id
                                      ? cls.tableRowSelected
                                      : selectedContainer?.exports?.includes(
                                          booking.id
                                        ) &&
                                        selectedContainer?.selected.type ===
                                          'em'
                                      ? cls.selectableItem
                                      : selectedContainer?.selected?.id ===
                                        booking.id
                                      ? cls.tableRowSelected
                                      : cls.tableRow
                                  }
                                  style={{ flex: 1 }}>
                                  {ExportsTableTheadTitle.map(
                                    (header, index) => {
                                      const objectKey = header.object;

                                      return header?.show === false ? (
                                        ''
                                      ) : (
                                        <td
                                          key={`${index}new`}
                                          className={cls.tableTDMain}
                                          style={{ flex: header.flex }}>
                                          {objectKey === 'booking'
                                            ? booking?.name
                                            : ''}
                                          {objectKey === 'name'
                                            ? booking?.container
                                            : ''}
                                          {objectKey === 'empty_released'
                                            ? formatDateTimeToUTC(
                                                booking?.empty_released
                                              )
                                            : ''}
                                          {objectKey === 'gated_in'
                                            ? formatDateTimeToUTC(
                                                booking?.gated_in
                                              )
                                            : ''}
                                          {objectKey === 'pickup_location'
                                            ? ex?.address?.city
                                            : ''}
                                          {objectKey === 'vessel'
                                            ? booking?.vessel_name
                                            : ''}
                                          {objectKey === 'voyage'
                                            ? booking?.vessel_voyage_in
                                            : ''}
                                          {objectKey === 'return_appointment'
                                            ? 'No Apt. Available'
                                            : ''}
                                          {objectKey === 'erd'
                                            ? formatDateTimeToUTC(booking?.erd)
                                            : ''}
                                          {objectKey === 'cutoff'
                                            ? formatDateTimeToUTC(
                                                booking?.cutoff
                                              )
                                            : ''}
                                          {objectKey === 'terminal_return'
                                            ? booking?.return_to_terminal?.slug
                                            : ''}
                                          {objectKey === 'vessel_departure'
                                            ? booking?.vessel_departure
                                            : ''}
                                        </td>
                                      );
                                    }
                                  )}
                                  <td
                                    className={cls.tableTDMain}
                                    style={{ flex: 1 }}>
                                    {hover === true ? (
                                      (selectedContainer?.exports?.includes(
                                        booking.id
                                      ) &&
                                        selectedContainer?.selected.type ===
                                          'em') ||
                                      selectedContainer?.selected?.id ===
                                        booking.id ? (
                                        savings[booking.id] ? (
                                          <span className={cls.savingSpan}>
                                            {formatPrice(savings[booking.id])}
                                            <ReactSVG
                                              src={Icons.dicsount}
                                              className={cls.dicsounticon}
                                            />
                                          </span>
                                        ) : (
                                          ''
                                        )
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr
                                  onClick={() =>
                                    handlematch(
                                      booking.id,
                                      'ex',
                                      i,
                                      null,
                                      booking.export_id
                                    )
                                  }
                                  className={
                                    selectedContainer?.selectedMatch?.id ===
                                    booking.id
                                      ? cls.tableRowSelected
                                      : selectedContainer?.exports?.includes(
                                          booking.id
                                        ) &&
                                        selectedContainer?.selected.type ===
                                          'em'
                                      ? cls.selectableItem
                                      : selectedContainer?.selected?.id ===
                                        booking.id
                                      ? cls.tableRowSelected
                                      : cls.tableRow
                                  }
                                  style={{ flex: 1 }}>
                                  {ExportsTableTheadTitle.map(
                                    (header, index) => {
                                      const objectKey = header.object;

                                      return header?.show === false ? (
                                        ''
                                      ) : (
                                        <td
                                          key={`${index}new`}
                                          className={cls.tableTDMain}
                                          style={{ flex: header.flex }}>
                                          {objectKey === 'booking'
                                            ? booking?.name
                                            : ''}
                                          {objectKey === 'name'
                                            ? booking?.container
                                            : ''}
                                          {objectKey === 'empty_released'
                                            ? formatDateTimeToUTC(
                                                booking?.empty_released
                                              )
                                            : ''}
                                          {objectKey === 'gated_in'
                                            ? formatDateTimeToUTC(
                                                booking?.gated_in
                                              )
                                            : ''}
                                          {objectKey === 'pickup_location'
                                            ? ex?.address?.city
                                            : ''}
                                          {objectKey === 'vessel'
                                            ? booking?.vessel_name
                                            : ''}
                                          {objectKey === 'voyage'
                                            ? booking?.vessel_voyage_in
                                            : ''}
                                          {objectKey === 'return_appointment'
                                            ? 'No Apt. Available'
                                            : ''}
                                          {objectKey === 'erd'
                                            ? formatDateTimeToUTC(booking?.erd)
                                            : ''}
                                          {objectKey === 'cutoff'
                                            ? formatDateTimeToUTC(
                                                booking?.cutoff
                                              )
                                            : ''}
                                          {objectKey === 'terminal_return'
                                            ? booking?.return_to_terminal?.slug
                                            : ''}
                                          {objectKey === 'vessel_departure'
                                            ? booking?.vessel_departure
                                            : ''}
                                        </td>
                                      );
                                    }
                                  )}
                                  <td
                                    className={cls.tableTDMain}
                                    style={{ flex: 1 }}>
                                    {hover === true ? (
                                      (selectedContainer?.exports?.includes(
                                        booking.id
                                      ) &&
                                        selectedContainer?.selected.type ===
                                          'em') ||
                                      selectedContainer?.selected?.id ===
                                        booking.id ? (
                                        savings[booking.id] ? (
                                          <span className={cls.savingSpan}>
                                            {formatPrice(savings[booking.id])}
                                            <ReactSVG
                                              src={Icons.dicsount}
                                              className={cls.dicsounticon}
                                            />
                                          </span>
                                        ) : (
                                          ''
                                        )
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))
                        )}
                      </td>
                    </tr>
                  )
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};
