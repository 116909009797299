import React, { useEffect, useState, useRef } from 'react';
import { Tooltip, IconButton, Checkbox } from '@material-ui/core';
import { usePusher } from '../../App';
import { DeleteRounded, CreateRounded } from '@material-ui/icons';
import { fDate, fTimeDate } from '@/actions/Helper';
import styles from './styles';
import { useNotify, Loading } from 'react-admin';
import {
  disableInventoryBooking,
  enableInventoryBooking,
  getInventoryShareLink,
} from '@/actions';
import Bookings from './Bookings';

export default ({
  DATA = [],
  Del,
  Edit,
  getExportInventoryList,
  getExportInventoryListNotLoading,
  currentPage,
  tableTheadTitle,
}) => {
  const cls = styles();
  const [bookingsData, setBookingsData] = useState(DATA);
  const { channels } = usePusher();
  const [showBookingsModal, setShowBookingsModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [refreshInventories, setRefreshInventories] = useState(false);
  const [shareLink, setShareLink] = useState({
    loading: null,
    DATA: null,
  });

  function replacebookingById(bookingsData, updatedbooking) {
    // Create a new array to ensure immutability
    return bookingsData.map((bookingGroup) => {
      const index = bookingGroup.bookings.findIndex(
        (booking) => booking.id === updatedbooking.id
      );

      if (index !== -1) {
        // Create a new bookings array with the updated booking
        const updatedBookings = bookingGroup.bookings.map((booking, i) =>
          i === index ? updatedbooking : booking
        );

        // Return a new bookingGroup with the updated bookings
        return {
          ...bookingGroup,
          bookings: updatedBookings,
        };
      }

      // If no changes, return the original bookingGroup
      return bookingGroup;
    });
  }

  useEffect(() => {
    if (channels?.privateChannel) {
      channels.privateChannel.bind('Export', (data) => {
        // console.log('Export event:', data);
        const updatedbooking = data.message;
        const updatedbookingsData = replacebookingById(
          bookingsData,
          updatedbooking
        );
        setBookingsData(updatedbookingsData);
      });

      return () => {
        channels.privateChannel.unbind('Export');
      };
    }
  }, [channels, bookingsData]);

  useEffect(() => {
    setBookingsData(DATA);
  }, [DATA]);
  const notify = useNotify();

  const getBookingNumbers = (exportList, target) => {
    if (exportList) {
      let activeBookings = [];
      let deactiveBookings = [];
      exportList.bookings.forEach((booking) => {
        if (booking.status === 1) {
          activeBookings.push(booking);
        } else if (booking.status === 0) {
          deactiveBookings.push(booking);
        }
      });

      if (deactiveBookings.length === 0) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeBookings.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };
  const formatUpdatedAtDate = (dateString) => {
    function isInvalidDate(dateStr) {
      return isNaN(Date.parse(dateStr));
    }

    if (dateString == 'please contact your line') {
      return dateString;
    } else if (dateString == 'TBA') {
      return dateString;
    } else if (isInvalidDate(dateString) == true) {
      return '';
    } else {
      const date = new Date(dateString);
      const formattedDate =
        new Date(date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
        }) +
        ' ' +
        new Date(date).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      // console.log(dateString);
      return formattedDate;
    }
  };

  const handleEnableCongtainer = (containerId, index) => {
    enableInventoryBooking(containerId).then((response) => {
      if (response.success) {
        notify('Booking updated');
        // updateContainer(index, 'status', 1);
        // setRefreshInventories(true);
        getExportInventoryListNotLoading();
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleDisableContainer = (containerId, index) => {
    disableInventoryBooking(containerId).then((response) => {
      if (response.success) {
        notify('Booking updated');
        // updateContainers(index, 'status', 0);
        // setRefreshInventories(true);
        getExportInventoryListNotLoading();
      } else {
        notify('somthing went wrong!');
      }
    });
  };

  const handleChangeContainerStatus = (value, containerId, index) => {
    if (value === true) {
      handleEnableCongtainer(containerId, index);
    } else {
      handleDisableContainer(containerId, index);
    }
  };

  const handleGetShareLink = (i) => {
    setShareLink({ loading: i.id });
    getInventoryShareLink(i.id)
      .then((res) => {
        setShareModal(i);
        setShareLink({
          loading: null,
          DATA: res,
        });
      })
      .catch((err) => {
        setShareLink({ loading: null });
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getBookingNumbers, getExportInventoryList]);
  let uniqueIndex = 0;

  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);

  const [tableWidth, setTableWidth] = useState(0);
  const [spanWidth, setSpanWidth] = useState(0);

  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    // Check if both hours and minutes are 00
    if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (amirbiosRef.current && amirbios1Ref.current) {
        const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;

        if (amirbiosTop <= 69) {
          amirbios2Ref.current.classList.add(cls.fix);
          tableRef.current.classList.add(cls.tableTopMargin);
        } else {
          amirbios2Ref.current.classList.remove(cls.fix);
          tableRef.current.classList.remove(cls.tableTopMargin);
        }
      }
      syncScroll('amirbios');
    };
    const updateWidth = () => {
      if (tableRef.current && amirbios1Ref.current) {
        const tableCurrentWidth =
          tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableCurrentWidth);
      }
    };

    const updateHeadWidth = () => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        const tableeCurrentWidth =
          amirbiosRef.current.getBoundingClientRect().width;
        setSpanWidth(tableeCurrentWidth);
      }
    };

    const syncScroll = (source) => {
      if (amirbiosRef.current || amirbios2Ref.current) {
        if (source === 'amirbios') {
          // console.log('sss');

          amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
        } else if (source === 'amirbios2') {
          // console.log('www');

          amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
        }
      }
    };

    updateWidth();
    updateHeadWidth();

    const handleScroll1 = () => syncScroll('amirbios');
    const handleScroll2 = () => syncScroll('amirbios2');

    if (amirbiosRef.current) {
      amirbiosRef.current.addEventListener('scroll', handleScroll1);
    }

    if (amirbios1Ref.current) {
      amirbios2Ref.current.addEventListener('scroll', handleScroll2);
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWidth);
    window.addEventListener('resize', updateHeadWidth);
    window.addEventListener('scroll', updateWidth);
    window.addEventListener('scroll', updateHeadWidth);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('resize', updateHeadWidth);
      window.removeEventListener('scroll', updateWidth);
      window.removeEventListener('scroll', updateHeadWidth);

      if (amirbiosRef.current) {
        amirbiosRef.current.removeEventListener('scroll', handleScroll1);
      }

      if (amirbios2Ref.current) {
        amirbios2Ref.current.removeEventListener('scroll', handleScroll2);
      }

      // if (amirbios2Ref.current) {
      //   amirbios2Ref.current.removeEventListener('scroll', () =>
      //     syncScroll('amirbios2')
      //   );
      // }
    };
  }, [bookingsData, amirbiosRef, amirbios2Ref]);

  useEffect(() => {
    if (amirbios2Ref.current) {
      amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
    }
  }, [spanWidth]);

  useEffect(() => {
    if (amirbios1Ref.current) {
      amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
    }
  }, [tableWidth]);

  return (
    <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
      {showBookingsModal && selectedRow && (
        <Bookings
          open={showBookingsModal}
          DATA={bookingsData}
          selectedRow={selectedRow}
          setShowBookingsModal={setShowBookingsModal}
          getExportInventoryList={getExportInventoryList}
        />
      )}
      {!tableTheadTitle ? (
        <Loading />
      ) : (
        <table ref={tableRef} id="table" className={cls.list}>
          <thead ref={amirbios2Ref} id="amirbios1">
            <tr ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
              <th style={{ flex: 0.35 }}>#</th>
              {tableTheadTitle.map((header, index) =>
                header?.show === false ? (
                  ''
                ) : (
                  <th key={index} style={{ flex: header.flex }}>
                    {header.label}
                  </th>
                )
              )}
              <th style={{ flex: 1.6 }}>Action</th>
            </tr>
          </thead>
          <tbody className={cls.tbody}>
            {
              bookingsData.map((i, idx) =>
                i.bookings.map((booking, idd) => {
                  uniqueIndex++;

                  return (
                    <tr className={cls.tableRow} key={`${uniqueIndex}new`}>
                      <td style={{ flex: 0.35 }}>
                        {uniqueIndex + (currentPage - 1) * 10}
                      </td>
                      {tableTheadTitle.map((header, index) => {
                        const objectKey = header.object;

                        return header?.show === false ? (
                          ''
                        ) : (
                          <td key={`${index}new`} style={{ flex: header.flex }}>
                            {['size', 'ssl', 'loc'].includes(objectKey)
                              ? i[objectKey]
                                ? i[objectKey]
                                : '-'
                              : ''}
                            {[
                              'name',
                              'return_appointment',
                              'return_terminals',
                              'return_dual_terminals',
                            ].includes(objectKey)
                              ? booking[objectKey]
                                ? booking[objectKey]
                                : '-'
                              : ''}
                            {objectKey === 'slug'
                              ? booking?.mto?.slug
                                ? booking?.mto?.slug
                                : '-'
                              : ''}
                            {objectKey === 'container'
                              ? booking['container']
                                ? booking['container']
                                : '-'
                              : ''}
                            {/* {objectKey === 'chassis'
                            ? booking['chassis']
                              ? booking['chassis']
                              : '-'
                            : ''} */}
                            {objectKey === '-' ? '-' : ''}
                            {objectKey === 'appointment_date'
                              ? booking['appointment']
                                ? fDate(booking['appointment'])
                                : '-'
                              : ''}
                            {objectKey === 'erd'
                              ? booking['erd']
                                ? formatDateTimeToUTC(booking['erd'])
                                : '-'
                              : ''}
                            {objectKey === 'gated_in'
                              ? booking['gated_in']
                                ? formatDateTimeToUTC(booking['gated_in'])
                                : '-'
                              : ''}
                            {objectKey === 'vessel_name'
                              ? booking['vessel_name']
                                ? booking['vessel_name']
                                : '-'
                              : ''}
                            {objectKey === 'vessel_voyage_in'
                              ? booking['vessel_voyage_in']
                                ? booking['vessel_voyage_in']
                                : '-'
                              : ''}
                            {objectKey === 'gated_out'
                              ? booking['gated_out']
                                ? formatDateTimeToUTC(booking['gated_out'])
                                : '-'
                              : ''}
                            {objectKey === 'load_pickup_date'
                              ? booking['load_pickup_date']
                                ? formatDateTimeToUTC(
                                    booking['load_pickup_date']
                                  )
                                : '-'
                              : ''}
                            {objectKey === 'pick_up_location'
                              ? booking?.pick_up_location?.slug
                                ? booking?.pick_up_location?.slug
                                : '-'
                              : ''}
                            {objectKey === 'vessel_departure'
                              ? booking['vessel_departure']
                                ? formatDateTimeToUTC(
                                    booking['vessel_departure']
                                  )
                                : '-'
                              : ''}
                            {objectKey === 'return_to_terminal'
                              ? booking?.return_to_terminal?.slug
                                ? booking?.return_to_terminal?.slug
                                : '-'
                              : ''}
                            {objectKey === 'cutoff'
                              ? booking['cutoff']
                                ? formatDateTimeToUTC(booking['cutoff'])
                                : '-'
                              : ''}
                            {objectKey === 'date'
                              ? i['date']
                                ? fDate(i['date'])
                                : '-'
                              : ''}
                            {objectKey === 'up'
                              ? i['up']
                                ? fTimeDate(i['up'])
                                : '-'
                              : ''}
                          </td>
                        );
                      })}

                      <td className={cls.tabActions} style={{ flex: 1.6 }}>
                        <Tooltip arrow title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => Edit(i, booking.id, booking)}>
                            <CreateRounded className={cls.editIcon} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title={booking.status === 1 ? 'Disable' : 'Enable'}
                          placement="top">
                          <IconButton>
                            <Checkbox
                              checked={booking.status === 1 ? true : false}
                              className={cls.checkbox}
                              onChange={(e) =>
                                handleChangeContainerStatus(
                                  e.target.checked,
                                  booking.id,
                                  idd
                                )
                              }
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip arrow title="Delete" placement="top">
                          <IconButton
                            aria-label="delete"
                            onClick={() => Del(booking.id)}>
                            <DeleteRounded className={cls.delIcon} />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                    // <tr className={cls.tableRow} key={uniqueIndex}>
                    //   <td style={{ flex: 0.35 }}>
                    //     {uniqueIndex + (currentPage - 1) * 10}
                    //   </td>
                    //   <td style={{ flex: 0.8 }}>{i.size}</td>
                    //   <td style={{ flex: 0.8 }}>{i.ssl}</td>
                    //   <td style={{ flex: 1 }}>
                    //     {booking.name ? booking.name : '-'}
                    //   </td>
                    //   <td style={{ flex: 1 }}>- </td>
                    //   <td style={{ flex: 1 }}>- </td>
                    //   <td style={{ flex: 1 }}>{i.loc}</td>
                    //   <th style={{ flex: 1 }}>-</th>
                    //   <th style={{ flex: 1 }}>-</th>
                    //   <th style={{ flex: 1 }}>-</th>
                    //   <td style={{ flex: 1 }}>
                    //     {booking?.additional?.erd
                    //       ? fDate(booking?.additional?.erd)
                    //       : '-'}
                    //   </td>
                    //   <td style={{ flex: 1 }}>
                    //     {booking?.additional?.cutoff
                    //       ? fDate(booking?.additional?.cutoff)
                    //       : '-'}
                    //   </td>
                    //   <td style={{ flex: 1 }}>
                    //     {booking?.additional?.appointment_date
                    //       ? fDate(booking?.additional?.appointment_date)
                    //       : '-'}
                    //   </td>
                    //   <td style={{ flex: 1 }}>
                    //     {booking.mto ? booking.mto?.slug : '-'}
                    //   </td>
                    //   <th style={{ flex: 1.1 }}>-</th>
                    //   <th style={{ flex: 1.1 }}>-</th>
                    //   <td style={{ flex: 1.2 }}>{fDate(i.date)}</td>
                    //   <td style={{ flex: 1.3 }}>{fTimeDate(i.up)}</td>
                    //   <td className={cls.tabActions} style={{ flex: 1.6 }}>
                    //     <Tooltip arrow title="Edit" placement="top">
                    //       <IconButton
                    //         aria-label="edit"
                    //         onClick={() => Edit(i, booking.id, booking)}>
                    //         <CreateRounded className={cls.editIcon} />
                    //       </IconButton>
                    //     </Tooltip>
                    //     <Tooltip
                    //       arrow
                    //       title={booking.status === 1 ? 'Disable' : 'Enable'}
                    //       placement="top">
                    //       <IconButton>
                    //         <Checkbox
                    //           checked={booking.status === 1 ? true : false}
                    //           className={cls.checkbox}
                    //           onChange={(e) =>
                    //             handleChangeContainerStatus(
                    //               e.target.checked,
                    //               booking.id,
                    //               idd
                    //             )
                    //           }
                    //         />
                    //       </IconButton>
                    //     </Tooltip>

                    //     <Tooltip arrow title="Delete" placement="top">
                    //       <IconButton
                    //         aria-label="delete"
                    //         onClick={() => Del(booking.id)}>
                    //         <DeleteRounded className={cls.delIcon} />
                    //       </IconButton>
                    //     </Tooltip>
                    //   </td>
                    // </tr>
                  );
                })
              )
              // )
            }
          </tbody>
        </table>
      )}
    </div>
  );
};
