import React from 'react';
import { useNotify, Loading } from 'react-admin';
import { Filter } from '@/components/Global';
import { getPortsList, getVesselSchedule } from '@/actions';
import List from './List';
import styles from './styles';
import Pagination from '@/components/pagination';
import Add from './Add';

export default (props) => {
  const cls = styles();
  const notify = useNotify();
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [1],
  });
  const [loading, setLoading] = React.useState(true);
  const [DATATable, setDataTable] = React.useState({});
  const [PortsList, setPortsList] = React.useState({});

  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });
  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const getGateScheduleList = () => {
    setLoading(true);
    getVesselSchedule()
      .then((res) => {
        let pages = [];
        for (let i = 1; i <= res.last_page; i++) {
          pages.push(i);
        }
        // console.log('res', res);
        // console.log('res.data.vessels.last_page', res.data.vessels.last_page);

        setPagination((prev) => {
          return {
            ...prev,
            pages: createArrayFromNumbers(
              Math.ceil(res.data.vessels.total / res.data.vessels.per_page)
            ),
          };
        });
        // console.log('pagination', pages);
        setDataTable(res.data.vessels);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getPortsListFanc = () => {
    setLoading(true);
    getPortsList()
      .then((res) => {
        setPortsList(Object.values(res.data.ports).map((ports) => ports));
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    // getPortsListFanc();
    getGateScheduleList();
  }, [pagination.currentPage]);
  return (
    <div id="inventoryEmpty" className={cls.content}>
      <div className={cls.cardHeader}>
        <span className={cls.title}>Vessel Schedule</span>
        <span>
          <span className={cls.title}>Export</span>
          <span
            className={cls.titleBlue}
            // onClick={handleExport}
          >
            XLSX
          </span>
        </span>
      </div>
      {/* <Add
        notify={notify}
        DATATable={DATATable}
        setData={(res) => {
          setFilter({ ...filter, DATATable });
        }}
      /> */}
      {/* <Filter
        title="Gate Schedule"
        filter={filter}
        setFilter={setFilter}
        DATA={DATATable}>
        <span className={cls.title}>Export</span>
        <span
          className={cls.titleBlue}
          // onClick={handleExport}
        >
          XLSX
        </span>
      </Filter>
 */}
      {loading ? (
        <Loading />
      ) : (
        <List
          DATA={DATATable}
          VesselsList={DATATable.data}
          PortsList={PortsList}
        />
      )}
      {/* <Pagination pagination={pagination} setPagination={setPagination} /> */}
    </div>
  );
};
