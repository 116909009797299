import About from './About';
import Amirbios from './Amirbios';
import Contact from './Contact';
import Dashboard from './Dashboard';
import ForgetPass from './ForgetPass';
import How from './How';
import Inventory from './Inventory/index';
import InventoryEmpty from './Inventory';
import ExportInventory from './ExportInventory';
import InventoryStreetTurn from './StreetTurn';
import Landing from './Landing';
import Login from './Login';
import Register from './Register';
import Order from './Order';
import OrderInComplete from './OrderInComplete';
import OrderComplete from './OrderComplete';
import Profile from './Profile';
import Report from './Report';
import Request from './Request';
import RequestDetail from './RequestDetail';
import RequestInComplete from './RequestInComplete';
import RequestComplete from './RequestComplete';
import SearchEmpty from './Search/SearchEmpty';
import SearchExport from './Search/SearchExport';
import EmptySearchDetail from './SearchDetail/Empty';
import ExportSearchDetail from './SearchDetail/Export';
import SharedContainer from './SharedContainer';
import Empty from './Empty';
import Ports from './Ports';
import GateSchedule from './GateSchedule';
import GateScheduleAdmin from './GateSchedule/indexAdmin';
import ReceivablesScheduleAdmin from './ReceivablesSchedule/indexAdmin';
import ReceivablesSchedule from './ReceivablesSchedule';
import VesselSchedule from './VesselSchedule';
import VesselScheduleAdmin from './VesselSchedule/indexAdmin';
import ScrapeSetting from './ScrapeSetting';

//admin pages

import Members from './Members';
import Admins from './Admins';
import Confirmed from './Content/Confirmed';
import AdminInventory from './Content/Inventory/Inventory';
import AdminCompleted from './Content/Completed/Completed';
import Chat from './Chat';
import Finance from './Finance';
import Update from './Update';
import Referral from './Referral';
import Privacy from './Privacy';
import Integration from './Integration';
import IntegrationExport from './Integration/Export/index';
import TMSManagment from './Integration/TMSManagment/index';
import Logs from './Logs';
import Alerts from './Alerts';
import AlertsAdd from './Alerts/AlertAdd';
import AdminExportList from './AdminExport/Export/index';
import AdminExportCompleted from './AdminExport/Completed/index';
import AdminExportDisputed from './AdminExport/Disputed/index';
import AIChat from './AIChat';
import AIGroup from './AIGroup';

export default {
  About,
  Amirbios,
  Contact,
  Dashboard,
  ForgetPass,
  How,
  Inventory,
  InventoryEmpty,
  ExportInventory,
  InventoryStreetTurn,
  Landing,
  Login,
  Register,
  Order,
  OrderInComplete,
  OrderComplete,
  Profile,
  Report,
  Request,
  RequestDetail,
  RequestInComplete,
  RequestComplete,
  SearchEmpty,
  SearchExport,
  EmptySearchDetail,
  ExportSearchDetail,
  Members,
  AdminInventory,
  AdminCompleted,
  Chat,
  Finance,
  Privacy,
  Integration,
  IntegrationExport,
  TMSManagment,
  Admins,
  Confirmed,
  Update,
  Referral,
  Logs,
  Alerts,
  AlertsAdd,
  SharedContainer,
  Empty,
  Ports,
  GateSchedule,
  GateScheduleAdmin,
  ReceivablesSchedule,
  ReceivablesScheduleAdmin,
  VesselSchedule,
  VesselScheduleAdmin,
  ScrapeSetting,
  AdminExportList,
  AdminExportDisputed,
  AdminExportCompleted,
  AIChat,
  AIGroup,
};
