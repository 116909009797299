import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  filterContainer: {
    width: '100%',
  },
  card: {
    position: 'relative',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    // height: 160,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 20,
  },
  containerInput: {
    display: 'flex',
    alignItems: 'center',
    // overflow: 'hidden',
    marginBottom: 10,
  },
  containerBadge: {
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    width: '50px',
    fontSize: 13,
    minWidth: '50px',
  },
  containerActions: {
    display: 'flex',
    alignItems: 'cneter',
    justifyContent: 'space-between',
  },
  containerRowRound: {
    overflow: 'hidden',
    width: '100%',
    minWidth: 160,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    backgroundColor: '#d7d7d7',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    cursor: 'pointer',
    ['@media (max-width:450px)']: {
      marginLeft: 0,
      marginTop: 5,
      width: '100%',
    },
  },
  chassisText: {
    textAlign: 'center',
    display: 'flex',
    color: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 5,
    flex: '1',
    '&:hover': {
      background: '#edecec',
    },
    '&:first-child': {
      borderRight: '2px solid #ffffff',
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  activeChassis: {
    backgroundColor: '#f8b100 !important',
    color: '#000 !important',
    '&:hover': {
      background: '#ffd616 !important',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 13,
  },
  row2: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  select: {
    width: '-webkit-fill-available',
    fontFamily: 'Averta Light',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div:first-child': {
      padding: '2px 8px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  selecttt: {
    width: '-webkit-fill-available',
    fontFamily: 'Averta Light',
    '& > div': {
      border: 'none',
    },
    '& > div:first-child > div:first-child': {
      padding: '2px 8px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },

  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    // border: 'solid 1px #707070',
    padding: '0 10px',
    '& input': {
      color: 'hsl(0,0%,50%) !important',
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
      fontSize: '13px !important',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
    '& > div': {
      fontSize: 13,
    },
  },
  searchIcon: {
    width: '18px',
    height: '18px',
    fill: '#E0E0E0',
    paddingRight: '10px',
  },
  searchInput: {
    '&::placeholder': {
      color: '#E0E0E0',
    },
  },
  search: {
    display: 'flex',
    alignItems: 'center',
  },
  searchBox: {
    height: '28px',
    background: '#ffffff',
    border: '1px solid #E0E0E0',
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    boxSizing: 'border-box',
    margin: '0 16px',
    '& input': {
      border: 'none',
      background: 'transparent',
      flex: '1',
      '&:focus-visible': {
        border: 'none !important',
        outline: 'none',
      },
    },
  },

  loc: {
    '& svg': {
      fill: '#f8b100',
    },
    fontFamily: 'Averta Light',
    width: '-webkit-fill-available',
    // marginLeft: 15,
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '@media (max-width:900px)': {
      marginLeft: 0,
    },
  },
  loccc: {
    '& svg': {
      fill: '#f8b100',
    },
    fontFamily: 'Averta Light',
    width: '-webkit-fill-available',
    // marginLeft: 15,
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      border: 'none',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '@media (max-width:900px)': {
      marginLeft: 0,
    },
  },

  ml15: {
    marginLeft: 15,
  },
  input: {
    fontFamily: 'Averta Light',
    width: '-webkit-fill-available',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    color: 'hsl(0,0%,50%) !important',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
      '&::placeholder': {
        color: '#000000',
      },
    },
    '& > div': {
      fontSize: 13,
    },
  },

  but: {
    width: 85,
    height: 30,
    borderRadius: 5,
    backgroundColor: '#222 !important',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
    marginTop: 15,
  },
  close: {
    position: 'absolute',
    top: 10,
    left: 10,
    height: 20,
    cursor: 'pointer',
    fill: '#e4464b',
  },
  filterRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  row3: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ['@media (max-width:760px)']: {
      marginTop: 25,
      marginBottom: 10,
    },
  },
  filter: {
    height: 20,
    width: 20,
    fill: '#9f9f9f',
    margin: 'auto auto auto 10px',
  },
  title: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#43424b',
  },
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px 10px 20px 10px',
    '@media (max-width:900px)': {
      flexDirection: 'column',
      margin: 10,
    },
  },
});
