import React, { useEffect, useState } from 'react';
import styles from './styles';
import { CircularProgress } from '@material-ui/core';
import {
  getAlertsList,
  getPortsList,
  getSslList,
  getTerminalsList,
} from '@/actions';
import { useNotify } from 'react-admin';
import Pagination from '@/components/pagination';
import List from './List';

const Alerts = () => {
  const [alerts, setAlerts] = React.useState({
    loading: false,
    DATA: [],
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pages: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [terminalsList, setTerminalsList] = useState([]);
  const [sslsList, setSslsList] = useState([]);
  const [portsList, setPortsList] = useState([]);

  const notify = useNotify();

  const cls = styles();

  const getPortsListFanc = () => {
    setLoading(true);
    getPortsList()
      .then((res) => {
        const portsRes = Object.values(res.data.ports);
        if (portsRes) {
          setPortsList(portsRes); // Set the state directly
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getTerminalsListFanc = () => {
    setLoading(true);
    getTerminalsList()
      .then((res) => {
        const terminalsRes = res.data.terminals;
        if (terminalsRes) {
          setTerminalsList(terminalsRes); // Set the state directly
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getSslListFanc = () => {
    setLoading(true);
    getSslList()
      .then((res) => {
        const sslsRes = Object.values(res.data.ssl_list);
        if (sslsRes) {
          setSslsList(sslsRes); // Set the state directly
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  useEffect(() => {
    getPortsListFanc();
    getTerminalsListFanc();
    getSslListFanc();
  }, []); // Empty dependency array to run only once on mount

  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const getAlerts = () => {
    // setAlerts((prev) => {
    //   return {
    //     ...prev,
    //     loading: true,
    //   };
    // });
    getAlertsList({ page: pagination.currentPage })
      .then((res) => {
        console.log('res', res);

        setAlerts((prev) => {
          return {
            loading: false,
            DATA: res.data,
          };
        });
        setPagination((prev) => {
          return {
            ...prev,
            pages: createArrayFromNumbers(Math.ceil(res.total / res.per_page)),
          };
        });
      })
      .catch((err) => {
        notify(err, 'warning');
        setAlerts((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
  };

  useEffect(() => {
    getAlerts();
  }, []);

  useEffect(() => {
    getAlerts();
  }, [notify, pagination.currentPage]);

  return (
    <div className={cls.content}>
      <h2 style={{ padding: '0 10px' }} className={cls.title}>
        Alerts
      </h2>
      {alerts.loading ? (
        <div className={cls.loading}>
          <CircularProgress className={cls.loader} />
        </div>
      ) : (
        <div style={{ overflow: 'auto', width: '100%' }}>
          <List
            portsList={portsList}
            terminalsList={terminalsList}
            sslsList={sslsList}
            getAlerts={getAlerts}
            DATA={alerts.DATA || []}
            notify={notify}
          />
        </div>
      )}
      <Pagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
};

export default Alerts;
